import React from 'react'
import ReactDOM from 'react-dom'
import { MainContainer } from 'containers'
import { ProjectPlugin } from 'components'
import './index.scss'
import reducers from './reducers'
import middleware from './middleware'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import { PersistGate } from 'redux-persist/integration/react'
import { DateTime } from 'luxon'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {
    TAG_SCALE_SECOND,
    TAG_SCALE_MINUTE,
    TAG_SCALE_HOUR,
    TAG_SCALE_DAY,
    TAG_SCALE_WEEK,
    TAG_SCALE_MONTH,
    TAG_SCALE_QUARTER,
    TAG_SCALE_YEAR
} from 'components'

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

let store = createStore(persistedReducer, middleware)
let persistor = persistStore(store)

class Loader extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            context: props.context
        }
        this.state.context.subscribe("TIMELINE_INIT", this, this.loader);

        const container = "app"
        this.loader(container, this)
    }
    loader (container, that)
    {
        console.log('TIMELINE_INIT', container, that)
        // const timeline = React.createElement(MainContainer, { context: that.props.context })
        const timeline = React.createElement(ProjectPlugin, { context: that.props.context })
        ReactDOM.render(
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    {timeline}
                </PersistGate>
            </Provider>,
            document.getElementById(container)
        );
    }
    render() {
        return (
            <div></div>
        )
    }
}

ReactDOM.render(
    React.createElement(Loader, { context: PubSubManager }),
    document.getElementById("app_loader")
);
