export const SET_SESSION = 'SET_SESSION'
export const UNSET_SESSION = 'UNSET_SESSION'

export function setSession (token) {
    return {
        type: SET_SESSION,
        token,
    }
}

export function unsetSession () {
    return {
        type: UNSET_SESSION,
    }
}
