import {
    SET_CURRENT_CLAIM,
    SET_CLAIM_DETAIL,
    UNSET_CLAIM_DETAIL,
} from '../actions/claim'

export default function claim (state = null, action) {
    switch (action.type) {
        case SET_CURRENT_CLAIM :
            return {
                ...state,
                currentClaim: action.claimId
            }
        case SET_CLAIM_DETAIL :
            return {
                ...state,
                [action.claimId]: action.detail
            }
        default :
            return state
    }
}
