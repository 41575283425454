exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".styles__container___1QCTT{width:100%}.styles__innerContainer___3R2Fu{max-width:900px;margin:0 auto}", "", {"version":3,"sources":["/Users/johnmcmullan/Dropbox/Awesome/McMullan Consulting/Projects/Delta/PubSubReactTimeline/web/app/containers/Main/styles.css"],"names":[],"mappings":"AAAA,2BACE,UAAY,CACb,AAED,gCACE,gBAAiB,AACjB,aAAe,CAChB","file":"styles.css","sourcesContent":[".container {\n  width: 100%;\n}\n\n.innerContainer {\n  max-width: 900px;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "styles__container___1QCTT",
	"innerContainer": "styles__innerContainer___3R2Fu"
};