import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  IndexRoute
} from 'react-router-dom'
import { Claim, Project } from 'components'
import { connect } from 'react-redux'
import { setCurrentClaim, handleGetClaimDetail } from 'actions'

class ClaimContainer extends React.Component {
    componentWillMount () {
    }
    render () {
        return (
                <div>
                    <Route path='/claim/:claimId' component={Claim} exact />
                    <Route path='/claim/:claimId/project' component={Project} />
                </div>
        )
    }
}

export default connect((state) => ({
    token: state.session,
    loginId: state.authenticatedUser.user.LoginID,
    claim: state.claim
}))(ClaimContainer)
