import {
    SET_SESSION,
    UNSET_SESSION,
} from '../actions/session'

export default function session (state = null, action) {
    switch (action.type) {
        case SET_SESSION :
            return action.token
        case UNSET_SESSION :
            return null
        default :
            return state
    }
}
