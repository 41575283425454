import React from 'react'
import { Link } from "react-router-dom";
import { Affix, Button, Menu, Icon, Divider, Layout, Input, Avatar, Badge, Dropdown, message, Drawer, Radio, Mention } from 'antd';
import './styles.css'
import { connect } from 'react-redux'
import { handleSignOut } from 'actions'

let dispatch = null

const { Header, Footer, Sider, Content } = Layout;
const { toString } = Mention;
const Search = Input.Search;
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const SearchPaneStyles = {
    margin: 0,
    padding: 7,
    backgroundColor: '#3e4243',
    borderBottom: '0px solid #e8e8e8',
    textAlign: 'right'
}
const SearchBoxStyles = {
    borderRadius: 12
}
const HeaderStyles = {
    backgroundColor: '#3e4243'
}
const NavBarStyles = {
    backgroundColor: '#3e4243'
}
const onAccountMenuClick = function ({ key }) {
    switch(key) {
        case 'signout':
            dispatch(handleSignOut())
            break
        default: break
    }
}
const AccountMenu = (
  <Menu onClick={onAccountMenuClick}>
    <Menu.Item key="signout">
      Sign out
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item key="3" disabled>3rd menu item（disabled）</Menu.Item>
  </Menu>
)

function onChange(editorState) {
  console.log(toString(editorState));
}

function onSelect(suggestion) {
  console.log('onSelect', suggestion);
}

const apps = ['afc163', 'benjycui', 'yiminghe', 'jljsj33', 'dqaria', 'RaoHai'];
const tags = ['1.0', '2.0', '3.0'];

class Navigation extends React.Component {
  constructor() {
    super();
    this.state = {
      suggestions: [],
      newAppVisible: false
    };
  }
  componentDidMount() {
      dispatch = this.props.dispatch
      console.log("NAV STATE", this.state);
  }
  showDrawer = () => {
      console.log('App');
      this.setState({
        newAppVisible: true,
      });
    }
    onClose = () => {
      this.setState({
        newAppVisible: false,
      });
    }
  handleClick = (e) => {
    console.log('click ', e);
    this.setState({
      current: e.key,
    });
  }
  onSearchChange = (value, trigger) => {
    console.log('onSearchChange', value, trigger);
    const dataSource = trigger === '$' ? apps : tags;
    this.setState({
      suggestions: dataSource.filter(item => item.indexOf(value) !== -1),
    });
  }
  render () {
    return (
      <div>
        <Affix>
        {/*
            <Button type="primary">Primary</Button>
            <Button>Default</Button>
            <Button type="dashed">Dashed</Button>
            <Button type="danger">Danger</Button>
            Navigation
            <Icon type="mail" />
            <Divider />
        */}
        <Layout>
            <Header
                style={HeaderStyles}
            >
                <Layout>

                    <Drawer
                              title=""
                              width={720}
                              placement="right"
                              onClose={this.onClose}
                              maskClosable={true}
                              visible={this.state.newAppVisible}
                              style={{
                                height: 'calc(100% - 55px)',
                                overflow: 'auto',
                                paddingBottom: 53,
                              }}
                            >
                                Yo!
                            </Drawer>
                    <Sider>
                        <div style={{display: 'flex'}}>
                            <Link to="/"><div className='tnkr_logo'></div></Link>
                            <Button onClick={this.showDrawer} type="dashed" shape="circle" icon="plus" size="small" style={{marginTop: '7px'}} />
                        </div>
                    </Sider>

                    <Content style={{textAlign: 'center', marginTop: '4px'}}>
                        <Mention
                            style={{ display: 'flex', verticalAlign: 'middle' }}
                            onChange={onChange}
                            placeholder="Search... "
                            prefix={['$', '#']}
                            onSearchChange={this.onSearchChange}
                            suggestions={this.state.suggestions}
                            onSelect={onSelect}
                          />
                    </Content>

                    <Sider style={{textAlign: 'right'}}>

                        <div>
                          <Radio.Group defaultValue="a" size="small" buttonStyle="solid" style={{marginRight: '20px'}}>
                          <Radio.Button value="a">DEV</Radio.Button>
                            <Radio.Button value="b">TEST</Radio.Button>
                            <Radio.Button value="c">PROD</Radio.Button>
                          </Radio.Group>
                          <Dropdown overlay={AccountMenu}>
                              <Badge count={this.props.info && this.props.info.actionCount} style={{cursor: 'pointer'}}>
                                  <Icon style={{ verticalAlign: 'middle', fontSize: '20px' }} type="user" />
                              </Badge>
                          </Dropdown>
                        </div>
                    </Sider>
               </Layout>

            </Header>
        </Layout>
        </Affix>
      </div>
    )
  }
}

export default connect((state) => ({
    user: state.authenticatedUser.user,
    info: state.authenticatedUser.info,
    claim: state.claim
}))(Navigation)
