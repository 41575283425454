import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  IndexRoute,
  Link
} from 'react-router-dom'
import { connect } from 'react-redux'
import socketIOClient from 'socket.io-client'
import { handleAuthentication, handleRegistration } from 'actions'
import {
  HomeContainer,
  AppContainer,
} from 'containers'
import { Navigation } from 'components'
import { container, innerContainer, csinput } from './styles.css'

import { Layout, Form, Icon, Input, Button, message, Checkbox } from 'antd';
const FormItem = Form.Item;
const { Header, Footer, Sider, Content } = Layout;

let dispatch = null;

class SignUp extends React.Component {
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const result = dispatch(handleRegistration(values.email, values.password, values.first, values.last ))
                    .then((result) => {
                        if(result !== undefined)
                            if(result.result === 'FAILURE')
                                message.error(result.message);
                              else
                                window.location = '/';
                    })
            }
            else {
                console.log('ERROR!', err);
            }
        });
    }
    render () {
        const { getFieldDecorator } = this.props.form;
        const LoginStyles = {
            margin: 'auto'
        }
        return (
            <div className={innerContainer}>
                <Layout>
                    <Header><Link to="/"><div className='logo' style={{marginLeft: 'auto', marginRight: 'auto'}}></div></Link></Header>
                    <Content className={LoginStyles} >
                        <Form onSubmit={this.handleSubmit} className="login-form">
                          <FormItem>
                              {getFieldDecorator('first', {
                                  rules: [{ required: true, message: 'Please provide your first name' }],
                              })(
                                  <Input placeholder="First" />
                              )}
                          </FormItem>
                          <FormItem>
                              {getFieldDecorator('last', {
                                  rules: [{ required: false, message: 'Please provide your last name' }],
                              })(
                                  <Input placeholder="Last" />
                              )}
                          </FormItem>
                          <FormItem>
                              {getFieldDecorator('email', {
                                  rules: [{ required: true, message: 'Please provide your email address' }],
                              })(
                                  <Input prefix={<Icon type="user" />} placeholder="Email" />
                              )}
                          </FormItem>
                            <FormItem>
                                {getFieldDecorator('password', {
                                    rules: [{ required: true, message: 'Please provide a password' }],
                                })(
                                    <Input prefix={<Icon type="lock" />} type="password" placeholder="Password" />
                                )}
                            </FormItem>
                            <FormItem>
                                <Button type="primary" htmlType="submit" className="login-form-button">
                                    Sign up
                                </Button>
                            </FormItem>
                        </Form>
                    </Content>
                    <Footer></Footer>
                </Layout>
            </div>
        )
    }
}

class SignIn extends React.Component {
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const result = dispatch(handleAuthentication(values.email, values.password))
                    .then((result) => {
                        if(result !== undefined)
                            if(result.result === 'FAILURE')
                                message.error(result.message);
                    })
            }
            else {
                console.log('ERROR!');
            }
        });
    }
    render () {
        const { getFieldDecorator } = this.props.form;
        const LoginStyles = {
            margin: 'auto',
            color: '#8a8a8a'
        }
        return (
            <div className={innerContainer}>
                <Layout>
                    <Header><Link to="/"><div className='logo' style={{marginLeft: 'auto', marginRight: 'auto'}}></div></Link></Header>
                    <Content className={LoginStyles}>
                        <Form onSubmit={this.handleSubmit} className="login-form">
                            <FormItem>
                                {getFieldDecorator('email', {
                                    rules: [{ required: true, message: 'Please provider your email address' }],
                                })(
                                    <Input prefix={<Icon type="user" />} placeholder="Email" />
                                )}
                            </FormItem>
                            <FormItem>
                                {getFieldDecorator('password', {
                                    rules: [{ required: true, message: 'Please input your Password!' }],
                                })(
                                    <Input prefix={<Icon type="lock" />} type="password" placeholder="Password" />
                                )}
                            </FormItem>
                            <FormItem>
                                {getFieldDecorator('remember', {
                                    valuePropName: 'checked',
                                    initialValue: true,
                                })(
                                    <Checkbox>Remember me</Checkbox>
                                )}
                                <a className="login-form-forgot" href="">Forgot password</a>
                                <Button type="primary" htmlType="submit" className="login-form-button">
                                    Sign in
                                </Button>
                                Or <a href="/register">register now!</a>
                            </FormItem>
                        </Form>
                    </Content>
                    <Footer></Footer>
                </Layout>
            </div>
        )
    }
}

const SignInForm = Form.create()(SignIn);
const SignUpForm = Form.create()(SignUp);

class Public extends React.Component {
    render () {
        return (
            <div>
              <Route path='/' component={SignInForm} exact />
              <Route path='/register' component={SignUpForm} exact />
            </div>
        )
    }
}

class Authenticated extends React.Component {
    state = {
        endpoint: "http://localhost:5000", // this is where we are connecting to with sockets
        color: 'white'
    }
    setColor = (color) => {
//        this.setState({ color })
        this.send(color)
    }
    // method for emitting a socket.io event

    send = (c) => {
        const socket = socketIOClient(this.state.endpoint);
        socket.emit('change color', c) // change 'red' to this.state.color
    }
    render () {
        // Within the render method, we will be checking for any sockets.
        // We do it in the render method because it is ran very often.
        const socket = socketIOClient(this.state.endpoint)

        // socket.on is another method that checks for incoming events from the server
        // This method is looking for the event 'change color'
        // socket.on takes a callback function for the first argument
        socket.on('change color', (col) => {
          document.body.style.backgroundColor = col
        })
        return (
            <div>
                <Navigation />
            {/*    <button id="blue" onClick={() => this.setColor('blue')}>Blue</button>
                <button id="red" onClick={() => this.setColor('red')}>Red</button> */}
                <Route path='/' component={HomeContainer} exact />
                <Route path='/app/:appId' component={AppContainer} />
            </div>
        )
    }
}

class MainContainer extends React.Component {
  componentDidMount() {
      dispatch = this.props.dispatch;
  }
  render () {
      console.log(this.props.user);
    return (
        <Router>
            <div className={container}>
                {this.props.user === null &&
                    <Public />
                }
                {this.props.user !== null &&
                    <Authenticated />
                }
            </div>
        </Router>
    )
  }
}

export default connect((state) => ({
    user: state.authenticatedUser
}))(MainContainer)
