"use strict";

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

Object.defineProperty(exports, "__esModule", { value: true });

var FlowItem = function FlowItem(data) {
    _classCallCheck(this, FlowItem);

    this.id = data.id;
    this.message = data.message;
    this.senderName = data.senderName || undefined;
    this.messageType = data.messageType || undefined;
};

exports.default = FlowItem;
