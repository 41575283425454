exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".styles__ant-input___3MgvB{border-radius:16px}", "", {"version":3,"sources":["/Users/johnmcmullan/Dropbox/Awesome/McMullan Consulting/Projects/Delta/PubSubReactTimeline/web/app/components/Navigation/styles.css"],"names":[],"mappings":"AAAA,2BACE,kBAAoB,CACrB","file":"styles.css","sourcesContent":[".ant-input {\n  border-radius: 16px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"ant-input": "styles__ant-input___3MgvB"
};