import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { Promise } from 'promise'
import sizeMe from 'react-sizeme'
import { DateTime } from 'luxon'
import Interval from 'luxon/src/interval.js'
import {
    container, odd, even, visible, hidden,
    tagCanvas, tagIntervalScale, tagIntervalContainer, tagInterval, tagIntervalLabelContainer, tagIntervalLabel, tagIntervalInfo,
    tagToolbar, tagToolbarContainer, tagLine, tagLineInfo, tagResouceList,
    tagLineContent, tagLineContentContainer, tagLineContentHeader, tagLineContentDescendants, tagLineContentDescendantsHidden,
    tagLineContentBar, tagLineContentBarEdit, tagLineContentBarStart, tagLineContentBarEnd, tagLineContentBarProgress, tagLineContentBarProgressSlider,
    tagLineContentBarGroupRight, tagLineContentBarGroupLeft,
    tagLegend, tagLegendOption,
    planned, dot, dotValue, dotValueVertical, dotDate, dotDeltaValue,
    tagLineStartControls, tagLineEndControls, tagLineControlStartWork, tagLineControlFinishWork,
    tagLineDependency, centeredBox, tagToday, gradientBorder
} from './styles.css'
import { ProjectUtility } from './Utility'
import { Layout, Button, Icon, Slider, Radio, Affix, Spin, Tooltip, Tag, message, Popconfirm, Drawer, Input, Form, Switch, Select } from 'antd'
import sizeof from 'object-sizeof'
const { CheckableTag } = Tag;
const ButtonGroup = Button.Group
const { TextArea } = Input
const FormItem = Form.Item;
const { Header, Footer, Sider, Content } = Layout
const { Option } = Select;

export const TAG_SCALE_SECOND               = 'TAG_SCALE_SECOND'
export const TAG_SCALE_MINUTE               = 'TAG_SCALE_MINUTE'
export const TAG_SCALE_HOUR                 = 'TAG_SCALE_HOUR'
export const TAG_SCALE_DAY                  = 'TAG_SCALE_DAY'
export const TAG_SCALE_WEEK                 = 'TAG_SCALE_WEEK'
export const TAG_SCALE_MONTH                = 'TAG_SCALE_MONTH'
export const TAG_SCALE_QUARTER              = 'TAG_SCALE_QUARTER'
export const TAG_SCALE_YEAR                 = 'TAG_SCALE_YEAR'

export const TAG_ACTION_STATE_PLANNED       = 'TAG_ACTION_STATE_PLANNED'
export const TAG_ACTION_STATE_ACTIVE        = 'TAG_ACTION_STATE_ACTIVE'
export const TAG_ACTION_STATE_COMPLETE      = 'TAG_ACTION_STATE_COMPLETE'

export const TAG_ACTION_SCALE               = 'TAG_ACTION_SCALE'
export const TAG_ACTION_PROPAGATE_MOVE      = 'TAG_ACTION_PROPAGATE_MOVE'
export const TAG_ACTION_PROPAGATE_END       = 'TAG_ACTION_PROPAGATE_END'

export const TAG_ACTION_BAR_NONE            = 'TAG_ACTION_BAR_NONE'
export const TAG_ACTION_BAR_PROGRESS        = 'TAG_ACTION_BAR_PROGRESS'
export const TAG_ACTION_BAR_START           = 'TAG_ACTION_BAR_START'
export const TAG_ACTION_BAR_END             = 'TAG_ACTION_BAR_END'
export const TAG_ACTION_BAR_MOVE            = 'TAG_ACTION_BAR_MOVE'
export const TAG_ACTION_CANVAS_MOVE         = 'TAG_ACTION_CANVAS_MOVE'

export const TAG_STATUS_BAR_GROUP           = '#2f3334'
export const TAG_STATUS_BAR_PLANNED         = '#898989'
export const TAG_STATUS_BAR_STARTED         = '#90C547'
export const TAG_STATUS_BAR_ATTENTION       = '#f5932E'
export const TAG_STATUS_BAR_COMPLETE        = '#90C547'

export const TAG_START_DRAG_DISTANCE_MIN    = 10
export const TAG_INTERVAL_WIDTH             = 200
export const TAG_INTERVAL_BUFFER            = 12

export const TAG_HEADER_HEIGHT              = 30
export const TAG_INTERVAL_HEADER_HEIGHT     = 40
export const TAG_LINE_HEADER_HEIGHT         = 30
export const TAG_LINE_MIN_WIDTH             = 20
export const TAG_LINE_CONTROLS_WIDTH        = 140

export const TAG_CANVAS_PADDING_HEIGHT      = 50
export const TAG_EDIT_FORM_HEIGHT           = 240

export const TAG_SCALE_DURATION             = 500
export const TAG_RELATIVE_MONTH_SECONDS     = 1000 * 60 * 60 * 24 * 30

export const PIXEL_STEP                     = 10;
export const LINE_HEIGHT                    = 40;
export const PAGE_HEIGHT                    = 800;

export const CURVE_HEADER                   = 100;
export const CURVE_FOOTER                   = 40;

export const CURVE_AXIS_INTERVAL_COUNT      = 4;
export const CURVE_AXIS_INTERVAL_WIDTH      = 100;
export const DOT_SCALE                      = 5;


let scrollTimerId = undefined

function hasErrors(fieldsError)
{
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}
class ProjectEditForm extends React.Component
{
    constructor(props)
    {
        super(props)
    }
    componentDidMount()
    {
        this.props.form.validateFields();
    }
    handleSubmit = (e) => {
        const t = this
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                message.loading("Submitting Extension of Time Request...", 0);
                this.props.onEditFormClose(values.reason)
            }
            else {
                message.error(values);
            }
        })
    }
    handleCancel = (e) => {
        const t = this
        e.preventDefault();
        this.props.onEditFormClose(undefined)
    }
    render()
    {
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
        const reasonError = isFieldTouched('reason') && getFieldError('reason');
        return (
            <Form onSubmit={this.handleSubmit}>
                <FormItem
                  validateStatus={reasonError ? 'error' : ''}
                  help={reasonError || ''}
                >
                  {getFieldDecorator('reason', {
                    rules: [{ required: true, message: 'Please provide a reason for these changes' }],
                  })(
                    <TextArea rows={5} placeholder="Please enter here the Reason for the Extension of Time request. A Reason is required for the request details to be saved." />
                  )}
                </FormItem>
                    <div
                        style={{
                          position: 'absolute',
                          bottom: 0,
                          width: '100%',
                          padding: '10px 16px',
                          textAlign: 'right',
                          left: 0,
                          background: '#fff',
                          borderRadius: '0 0 4px 4px',
                        }}
                      >
                        <Button
                          style={{
                            marginRight: 8,
                          }}
                          onClick={this.handleCancel}
                        >
                          Cancel
                        </Button>
                        <Button
                            disabled={hasErrors(getFieldsError())}
                            htmlType="submit"
                            type="primary"
                        >Submit</Button>
                    </div>
            </Form>
        )
    }
}

const WrappedProjectEditForm = Form.create()(ProjectEditForm)

class ProjectEdit extends React.Component
{
    constructor(props)
    {
        super(props)
    }
    render()
    {
        return (
            <Drawer
                title="Reason for Extension of Time Request"
                placement="bottom"
                closable={false}
                onClose={this.props.onEditFormClose}
                visible={this.props.editFormVisible}
                mask={false}
                height={TAG_EDIT_FORM_HEIGHT}
            >
                <WrappedProjectEditForm wrappedComponentRef={(form) => this.form = form} onEditFormClose={this.props.onEditFormClose} />
            </Drawer>
        )
    }
}

class ProjectLineDependency extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            draw: true,
            parentLine: this.props.parentLine,
            childLine: this.props.childLine,
        }
    }
    render()
    {
        const pl = this.props.parentLine
        const cl = this.props.childLine
        let left, top, width, height
        let p = ""

        if( pl.left     == undefined ||
            pl.right    == undefined ||
            pl.width    == undefined ||
            pl.top      == undefined ||
            cl.left     == undefined ||
            cl.right    == undefined ||
            cl.width    == undefined ||
            cl.top      == undefined
        ) this.state.draw = false
        else this.state.draw = true

        if(this.state.draw)
        {
            left        = (pl.left < cl.left ? pl.left : cl.left) - 5
            top         = (pl.top < cl.top ? pl.top : cl.top)
            width       = (pl.right > cl.right ? pl.right : cl.right) - left + 10
            height      = Math.abs(pl.top - cl.top) + 24 + 10

            let fromX   = Math.abs(pl.width) + 10
            let fromY   = 16
            let toX     = cl.left - left - 5
            let toY     = cl.top - top + 16
            let midX    = fromX + ((toX - fromX) / 2)
            let midY    = fromY + ((toY - fromY) / 2)

            p = "M" + fromX + " " + fromY + " Q " + (fromX + 20) + " " + fromY + ", " + midX + " " + midY + " T " + toX + " " + toY
        }
        return(
            <svg className={tagLineDependency} width={width} height={height} style={{position: 'fixed', left: left, top: top}} xmlns="http://www.w3.org/2000/svg">
                {this.state.draw &&
                    <path d={p} stroke="black" fill="transparent"/>
                }
            </svg>
        )
    }
}

class ProjectLine extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            context: props.context,
            start: this.props.start,
            blockAnimation: this.props.blockAnimation,
            offset: this.props.offset,
            scale: this.props.scale,
            line: this.props.line,
            activeLine: this.props.activeLine,
            lines: this.props.lines,
            onUpdate: this.props.onUpdate,
            onLineEdit: this.props.onLineEdit,
            onActiveLine: this.props.onActiveLine,
            onChangeTop: this.props.onChangeTop,
            viewTop: this.props.viewTop,
            viewBottom: this.props.viewBottom,
            disabled: false,
            dragging: false,
            moveX: this.props.moveX,
            moveY: this.props.moveY,
            collapse: this.props.collapse,
            onScreen: false,
            action: TAG_ACTION_BAR_NONE,
            displayProgress: false,
            displayControls: false,
            displayEditFormOnDragEnd: false,
        }
    }
    componentWillMount()
    {
        this.onScreen()
    }
    componentDidMount()
    {
        window.addEventListener('scroll', this.onScreen.bind(this));
        this.onScreen()
        this.state.context.subscribe("TIMELINE_CANVAS_DRAG_END", this, this.onCanvasDragEnd);
    }
    componentWillUnmount()
    {
        window.removeEventListener('scroll', this.onScreen.bind(this));
    }
    componentWillUpdate(nextProps, nextState)
    {
        if (nextState.collapse != this.state.collapse) this.onScreen()
        if (nextState.line.instance != this.state.line.instance) this.props.onUpdate();
        if (nextState.line.top != this.state.line.top) this.props.onUpdate();

        this.onScreen()
    }
    initDimensions()
    {
        let left = this.left(this.props.scale)
        let width = this.width(this.props.scale)

        this.props.line.left = left
        this.props.line.width = width
        this.props.line.right = left + Math.abs(width)
    }
    onProgressEnter() { this.setState({displayProgress: true}) }
    onProgressLeave() { if(!this.state.dragging) this.setState({displayProgress: false}) }
    onHoverStart()
    {
        if(!this.state.displayControls)
            this.setState({ displayControls: true});
        if(!this.state.displayNote)
            this.setState({ displayNote: true});
    }
    onHoverLeave()
    {
        if(this.state.displayControls)
            this.setState({ displayControls: false});
        if(this.state.displayNote)
            this.setState({ displayNote: false});
    }
    onDragStart(e)
    {
        const selector  = e.target.className.replace(' ant-tooltip-open','')
        let action      = this.state.action

        switch (selector)
        {
            case tagLineContentBar:
            case tagLineContentBarEdit:
            case tagLineContentBarProgress:         action = TAG_ACTION_BAR_MOVE;        break;
            case tagLineContentBarProgressSlider:   action = TAG_ACTION_BAR_PROGRESS;    break;
            case tagLineContentBarStart:            action = TAG_ACTION_BAR_START;       break;
            case tagLineContentBarEnd:              action = TAG_ACTION_BAR_END;         break;
            case tagLineContent:                    action = TAG_ACTION_CANVAS_MOVE;     break;
            default:                                action = TAG_ACTION_BAR_NONE;        break;
        }

        console.log(action, this.props.scale);

        if(action != TAG_ACTION_BAR_NONE)
        {
            const startX = typeof e.clientX === 'undefined' ? e.changedTouches[0].clientX : e.clientX;
            const startY = typeof e.clientY === 'undefined' ? e.changedTouches[0].clientY : e.clientY;
            const initX = startX
            const initY = startY

            console.log("initX", initX);

            const displayProgress = action == TAG_ACTION_BAR_PROGRESS ? true : false;

            const state = {
                dragging: true,
                action,
                startX,
                startY,
                initX,
                initY,
                displayProgress
            };

            if(action != TAG_ACTION_CANVAS_MOVE) e.stopPropagation();

            this.props.onActiveLine(this.props.line.id)
            this.setState(state);
        }
    }
    onDragMove(e)
    {
        if(!this.state.dragging) return

        const x = typeof e.clientX === 'undefined' ? e.changedTouches[0].clientX : e.clientX;
        const y = typeof e.clientY === 'undefined' ? e.changedTouches[0].clientY : e.clientY;
        this.onUpdateMove(x, y)
    }
    onUpdateMove(x, y)
    {
        const dx = x - this.state.startX;
        const dy = y - this.state.startY;

        let isEditing = false
        let displayEditFormOnDragEnd = this.state.displayEditFormOnDragEnd

        switch (this.state.action)
        {
            case TAG_ACTION_BAR_PROGRESS:
                let left = this.left(this.props.scale)
                let width = parseInt(Math.abs(this.width(this.props.scale)))
                let progress = Math.clamp(x - left, 0, width)
                this.props.line.complete = Math.round((progress / width) * 100);
                if(this.props.line.status == TAG_ACTION_STATE_COMPLETE && this.props.line.complete < 100)
                    this.props.line.status = TAG_ACTION_STATE_ACTIVE
                else if(this.props.line.status == TAG_ACTION_STATE_PLANNED && this.props.line.complete > 0)
                    this.props.line.status = TAG_ACTION_STATE_ACTIVE
                else if(this.props.line.status == TAG_ACTION_STATE_ACTIVE && this.props.line.complete == 100)
                    this.props.line.status = TAG_ACTION_STATE_COMPLETE
                break;
            case TAG_ACTION_BAR_MOVE:
                switch (this.props.scale) {
                    default:
                    case TAG_SCALE_HOUR:
                    case TAG_SCALE_DAY:
                    case TAG_SCALE_WEEK:
                    case TAG_SCALE_MONTH:
                    case TAG_SCALE_QUARTER:
                    case TAG_SCALE_YEAR:
                        this.props.line.start   = this.props.line.start.plus({ milliseconds: ((dx / TAG_INTERVAL_WIDTH) * ProjectUtility.getSeconds(this.props.line.start, this.props.scale)).toFixed(10) });
                        this.props.line.end     = this.props.line.end.plus({ milliseconds: ((dx / TAG_INTERVAL_WIDTH) * ProjectUtility.getSeconds(this.props.line.end, this.props.scale)).toFixed(10) });
                        isEditing = true;
                        break;
                }
                displayEditFormOnDragEnd = true
                //this.props.onLineEdit()
                break;
            case TAG_ACTION_BAR_END:
                switch (this.props.scale) {
                    default:
                    case TAG_SCALE_HOUR:
                    case TAG_SCALE_DAY:
                    case TAG_SCALE_WEEK:
                    case TAG_SCALE_MONTH:
                    case TAG_SCALE_QUARTER:
                    case TAG_SCALE_YEAR:
                        if(x > this.props.line.left)
                            this.props.line.end     = this.props.line.end.plus({ milliseconds: ((dx / TAG_INTERVAL_WIDTH) * ProjectUtility.getSeconds(this.props.line.end, this.props.scale)).toFixed(8) });
                        if(this.props.line.end < this.props.line.start) this.props.line.end = this.props.line.start
                        isEditing = true;
                            break;
                }
                displayEditFormOnDragEnd = true
                //this.props.onLineEdit()
                break;
            default: break;
        }

        this.props.line.diff_left_TAG_SCALE_HOUR    = undefined
        this.props.line.diff_left_TAG_SCALE_DAY     = undefined
        this.props.line.diff_left_TAG_SCALE_WEEK    = undefined
        this.props.line.diff_left_TAG_SCALE_MONTH   = undefined
        this.props.line.diff_left_TAG_SCALE_QUARTER = undefined
        this.props.line.diff_left_TAG_SCALE_YEAR    = undefined
        this.props.line.diff_width_TAG_SCALE_HOUR   = undefined
        this.props.line.diff_width_TAG_SCALE_DAY    = undefined
        this.props.line.diff_width_TAG_SCALE_WEEK   = undefined
        this.props.line.diff_width_TAG_SCALE_MONTH  = undefined
        this.props.line.diff_width_TAG_SCALE_QUARTER= undefined
        this.props.line.diff_width_TAG_SCALE_YEAR   = undefined

        this.props.line.left = this.left(this.props.scale)
        this.props.line.width = this.width(this.props.scale)

        this.props.onUpdate()

        if(this.props.line.edit) isEditing = true

        this.props.line.edit = isEditing

        this.setState({
            startX: x,
            startY: y,
            displayEditFormOnDragEnd,
        })
    }
    onDragEnd(e)
    {
        if(this.state.displayEditFormOnDragEnd) this.props.onLineEdit()
        if(!this.state.dragging) return

        switch (this.state.action)
        {
            case TAG_ACTION_BAR_PROGRESS:
                if(e != undefined) e.stopPropagation();
                this.updateProgress()
                break;
            case TAG_ACTION_CANVAS_MOVE:
                return;
                break;
        }

        const mouseDistance = ProjectUtility.getDistance(
            this.state.initX,
            this.state.initY,
            this.state.startX,
            this.state.startY
        )
        this.setState({
            dragging: false,
            displayProgress: false,
            displayEditFormOnDragEnd: false,
            startX: 0,
            startY: 0,
            initX: 0,
            initY: 0,
        });

        console.log('mouseDistance', mouseDistance, this.props.line.collapse)

        if(mouseDistance < TAG_START_DRAG_DISTANCE_MIN)
            if(this.props.line.id == this.props.activeLine)
                this.props.onCollapse(this.props.line.id)

                // this.props.line.collapse = !this.props.line.collapse

        console.log('mouseDistance', mouseDistance, this.props.line.collapse, this.state.action)
        // e.preventDefault();
        e.stopPropagation();

    }
    onCanvasDragEnd(data, that)
    {
        that.onDragEnd(undefined)
    }
    left(scale)
    {
        let left = 0
        if(!this.props.start) return left
        switch (scale) {
            default:
            case TAG_SCALE_HOUR:    left = (this.props.line.start.diff(this.props.start, 'hours').hours     * TAG_INTERVAL_WIDTH) + this.props.offset;  break;
            case TAG_SCALE_DAY:     left = (this.props.line.start.diff(this.props.start, 'days').days       * TAG_INTERVAL_WIDTH) + this.props.offset;  break;
            case TAG_SCALE_WEEK:    left = (this.props.line.start.diff(this.props.start, 'weeks').weeks     * TAG_INTERVAL_WIDTH) + this.props.offset;  break;
            case TAG_SCALE_MONTH:   left = (this.props.line.start.diff(this.props.start, 'months').months   * TAG_INTERVAL_WIDTH) + this.props.offset;  break;
            case TAG_SCALE_QUARTER: left = ((this.props.line.start.diff(this.props.start, 'months').months/3)   * TAG_INTERVAL_WIDTH) + this.props.offset;  break;
            case TAG_SCALE_YEAR:    left = (this.props.line.start.diff(this.props.start, 'years').years     * TAG_INTERVAL_WIDTH) + this.props.offset;  break;
        }
        return left
    }
    width(scale)
    {
        let width = 0
        if(!this.props.start) return width
        switch (scale) {
            default:
            case TAG_SCALE_HOUR:    width = (this.props.line.start.diff(this.props.line.end, 'hours').hours     * TAG_INTERVAL_WIDTH);  break;
            case TAG_SCALE_DAY:     width = (this.props.line.start.diff(this.props.line.end, 'days').days       * TAG_INTERVAL_WIDTH);  break;
            case TAG_SCALE_WEEK:    width = (this.props.line.start.diff(this.props.line.end, 'weeks').weeks     * TAG_INTERVAL_WIDTH);  break;
            case TAG_SCALE_MONTH:   width = (this.props.line.start.diff(this.props.line.end, 'months').months   * TAG_INTERVAL_WIDTH);  break;
            case TAG_SCALE_QUARTER:   width = ((this.props.line.start.diff(this.props.line.end, 'months').months/3)   * TAG_INTERVAL_WIDTH);  break;
            case TAG_SCALE_YEAR:    width = (this.props.line.start.diff(this.props.line.end, 'years').years     * TAG_INTERVAL_WIDTH);  break;
        }
        return width
    }
    contentBarlineStatus()
    {
        // if(this.props.line.childCount > 0)
        // {
        //     return TAG_STATUS_BAR_GROUP
        // }
        switch(this.props.line.status)
        {
            case TAG_ACTION_STATE_COMPLETE: return TAG_STATUS_BAR_COMPLETE; break;
            case TAG_ACTION_STATE_PLANNED:
            default:
                const start     = this.props.line.start.toMillis()
                const end       = this.props.line.end.toMillis()
                const today     = this.props.today.toMillis()
                const progress  = (end - start) * (this.props.line.complete / 100)
                const remaining = end - (start + progress)

                if(this.props.line.status == TAG_ACTION_STATE_PLANNED && start > today && progress == 0)
                {
                    return TAG_STATUS_BAR_PLANNED;
                    break;
                }

                if(start + progress > today)
                    return TAG_STATUS_BAR_COMPLETE;
                else
                {
                    const recorded      = today - start - progress
                    const indicator     = parseInt((remaining / recorded) * 100)
                    const cssStart      = 'linear-gradient(90deg, '
                    const cssProgress   = TAG_STATUS_BAR_STARTED + ' ' + this.props.line.complete +  '%,'
                    const cssEnd        = TAG_STATUS_BAR_ATTENTION + ' ' + indicator +  '%)'

                    return cssStart + cssProgress + cssEnd
                }
                break;
        }
    }
    onScreen()
    {
        let isOnScreen = true
        const l = this.props.line
        const bottom = l.top + TAG_LINE_HEADER_HEIGHT

        if(l.collapse) isOnScreen = false
        else if(
            (l.top > (this.props.viewTop - TAG_LINE_HEADER_HEIGHT) && bottom < (this.props.viewBottom ) + 10)
        ) isOnScreen = true
        else if(this.props.viewTop == 0 || this.props.viewBottom == 0) isOnScreen = true
        else isOnScreen = false

        //console.log('isOnScreen ' + l.top, this.props.viewTop, this.props.viewBottom, isOnScreen)

        if(this.state.onScreen != isOnScreen) this.setState({ onScreen: isOnScreen })
    }
    confirmStart()
    {
        this.props.line.status = TAG_ACTION_STATE_ACTIVE
        this.state.context.push("TIMELINE_UPDATE_STATUS", {
            trigger: "START",
            line: this.props.line
        });
        message.loading('Updating work started...', 0);
    }
    confirmFinish()
    {
        this.props.line.complete = 100
        this.props.line.status = TAG_ACTION_STATE_COMPLETE
        this.state.context.push("TIMELINE_UPDATE_STATUS", {
            trigger: "FINISHED",
            line: this.props.line
        });
        message.loading('Updating work finished...', 0);
    }
    updateProgress()
    {
        this.state.context.push("TIMELINE_UPDATE_STATUS", {
            trigger: "PROGRESS",
            line: this.props.line
        });
        message.loading('Updating progress...', 0);
    }
    addVisit()
    {
        this.state.context.push("TIMELINE_ADD_VISIT", {
            line: this.props.line
        });
    }
    displayLevel(level)
    {
        return this.props.selectedLevels.indexOf(level) > -1 ? true : false
    }
    isGroup()
    {
        return this.props.line.childCount > 0 ? true : false
    }
    render()
    {
        if(this.state.dragging && (this.state.startX != this.props.moveX || this.state.startY != this.props.moveY) ) this.onUpdateMove(this.props.moveX, this.props.moveY)
        let left = this.props.line.left //== undefined ? this.left(this.props.scale) : this.props.line.left
        let width = this.props.line.width //== undefined ? this.width(this.props.scale) : this.props.line.width
        let completeWidth = (width * (this.props.line.complete / 100))
        let animate = 'none'
        this.props.line.right = left + Math.abs(width)
        if(!this.props.blockAnimation && !this.state.dragging) animate = 'all 500ms ease-in-out'
        const parentLine = this.props.line
        const animateSlider = 'all 100ms ease-in-out'
        const topPx = this.props.line.top + 'px';
        const leftPx = left + 'px';
        const widthPx = parseInt(Math.abs(width)) + 'px';
        const editWidthPx = parseInt(Math.abs(width) + 4) + 'px';
        const completeWidthPx = (Math.abs(completeWidth) ) + 'px';
        const startLeftPx = (left - 20) + 'px';
        const endLeftPx = parseInt(left + Math.abs(width) - 13) + 'px';
        const sliderLeftPx = Math.clamp(left + Math.abs(completeWidth),
                                (left),
                                (left + 5) > (left + (Math.abs(completeWidth) - 10)) ? (left + 5) : (left + parseInt(Math.abs(completeWidth) - 10))
                            ) + 'px';
        const startControlsleftPx = (left - TAG_LINE_CONTROLS_WIDTH) + 'px';
        const endControlsLeftPx = (left + parseInt(Math.abs(width)) ) + 'px';
        const groupLeftPx = (left ) + 'px';
        const groupRightPx = (left + parseInt(Math.abs(width)) - 9 ) + 'px';
        const displayContentBar = this.isGroup() ? 'hidden' : 'visible'
        const lineStatus = this.contentBarlineStatus()
        const lineBorder = this.isGroup() ? 'solid 3px #2f3334' : 'none'
        const lineHeight = this.isGroup() ? '4px' : '10px'
        const { disabled } = this.state;
        return (
            <div>
            {this.state.onScreen && !this.props.collapse &&
                <div className={tagLine}
                    ref={(el) => this.instance = el }
                    onMouseDown={this.onDragStart.bind(this)}
                    onTouchStart={this.onDragStart.bind(this)}
                    onMouseMove={this.onDragMove.bind(this)}
                    onTouchMove={this.onDragMove.bind(this)}
                    onMouseUp={this.onDragEnd.bind(this)}
                    onTouchEnd={this.onDragEnd.bind(this)}
                    onMouseEnter={this.onHoverStart.bind(this)}
                    onMouseLeave={this.onHoverLeave.bind(this)}
                    style={{top: topPx}}
                >
                        <div className={tagLineInfo}>
                                {this.props.line.label}
                                {/*}
                            <Tooltip placement="right" title="Add visit">
                                <Button style={{float: 'right'}} type="dashed" shape="circle" icon="plus" onClick={this.addVisit.bind(this)}/>
                            </Tooltip>
                            */}
                        </div>
                    <div className={tagLineContent}>
                    {this.isGroup() &&
                        <div className={tagLineContentBarGroupLeft} style={{left: groupLeftPx, transition: animate}} />
                    }
                    {this.isGroup() &&
                        <div className={tagLineContentBarGroupRight} style={{left: groupRightPx, transition: animate}} />
                    }
                        <div className={tagLineContentContainer}>
                            <div className={tagLineContentHeader}>
                                    <div className={tagLineContentBar} style={{width: widthPx, left: leftPx, background: lineStatus, border: lineBorder, height: lineHeight, transition: animate}}>
                                        {this.props.line.edit && //false &&
                                            <div className={tagLineContentBarEdit} style={{width: editWidthPx, left: '0px', transition: animate}}></div>
                                        }
                                    </div>
                                <div className={tagLineContentBarProgress} style={{width: completeWidthPx, left: leftPx, transition: animate}} ></div>
                                <div className={tagLineContentBarEnd} style={{left: endLeftPx, transition: animate, visibility: displayContentBar}} ></div>
                                <Tooltip placement="top" title={`${this.props.line.complete}`}
                                    visible={this.state.displayProgress}
                                    onMouseEnter={this.onProgressEnter.bind(this)}
                                    onMouseLeave={this.onProgressLeave.bind(this)}
                                >
                                    <div className={tagLineContentBarProgressSlider} style={{left: sliderLeftPx, transition: animate, visibility: displayContentBar}} ></div>
                                </Tooltip>
                                &nbsp;
                            </div>

                            {this.state.displayControls && this.props.line.status == TAG_ACTION_STATE_PLANNED &&
                                <div className={tagLineStartControls} style={{left: startControlsleftPx, transition: animate}}>
                                    <ButtonGroup size="small">
                                        <Popconfirm placement="top" title="Confirm started" onConfirm={this.confirmStart.bind(this)} okText="Yes" cancelText="No">
                                            <Button type="dashed" ghost><div className={tagLineControlStartWork} /></Button>
                                        </Popconfirm>
                                    </ButtonGroup>
                                </div>
                            }
                            {this.state.displayControls && this.props.line.status == TAG_ACTION_STATE_ACTIVE &&
                                <div className={tagLineEndControls} style={{left: endControlsLeftPx, transition: animate}}>
                                    <ButtonGroup size="small">
                                        <Popconfirm placement="top" title="Confirm completed" onConfirm={this.confirmFinish.bind(this)} okText="Yes" cancelText="No">
                                            <Button type="dashed" ghost><div className={tagLineControlFinishWork} /></Button>
                                        </Popconfirm>
                                    </ButtonGroup>
                                </div>
                            }

                        </div>
                    </div>

                </div>
            }
                { !this.props.collapse &&
                    this.state.line.fragments.map((l, index) =>
                        <ProjectLine
                            ref={(el) => l.instance = el }
                            line={l}
                            activeLine={this.props.activeLine}
                            lines={this.props.lines}
                            key={l.id}
                            collapse={l.collapse}
                            onUpdate={this.props.onUpdate}
                            onLineEdit={this.props.onLineEdit}
                            onActiveLine={this.props.onActiveLine}
                            onChangeTop={this.props.onChangeTop}
                            offset={this.props.offset}
                            blockAnimation={this.props.blockAnimation}
                            start={this.props.start}
                            scale={this.props.scale}
                            viewTop={this.props.viewTop}
                            viewBottom={this.props.viewBottom}
                            dragOffset={this.props.dragOffset}
                            moveX={this.props.moveX}
                            moveY={this.props.moveY}
                            context={this.props.context}
                            today={this.props.today}
                            selectedLevels={this.props.selectedLevels}
                        />
                    )
                }
                {this.props.children}
            </div>
        )
    }
}

class Project extends React.Component
{
    constructor(props)
    {
        super(props)
        let startDate = new DateTime.local(2019, 3, 2)
        this.state = {
            context: props.context,
            matrix: [1, 0, 0, 1, 0, 0],
            dragging: false,
            scrolling: false,
            resizing: false,
            lastScrolling: 0,
            firstRender: undefined,
            editFormVisible: false,
            dragOffset: {
                x: 0,
                y: 0
            },
            contentHeight: 0,
            viewOffset: 0,
            viewTop: 0,
            viewBottom: 0,
            timeline: props.context.timeline,
            displayApproveReject: true,
            displayDependencies: true,
            displayCurves: true,
            displayPlan: true,
            selectedLevels: []
        }
        console.log("TIMELINE", this.state.timeline);

        this.state.context.subscribe("TIMELINE_LOAD", this, this.onGetTimelineLoad);
        this.state.context.subscribe("TIMELINE_TEST_READY", this, this.testData);
        this.state.context.subscribe("TIMELINE_MESSAGE", this, this.onMessage);
    }
    testData (data, that)
    {
        var startDate = new DateTime.local(2019, 3, 22)
        PubSubManager.push("TIMELINE_LOAD", {
            timeline: {
                start: startDate,
                view: startDate,
                scale: TAG_SCALE_WEEK,
                activeLine: undefined,
                lines: [
                    {
                        id: "a",
                        status: TAG_ACTION_STATE_ACTIVE,
                        edit: false,
                        label: "Top thing",
                        originalStart: new DateTime.local(2019, 3, 11),
                        originalEnd: new DateTime.local(2019, 4, 7),
                        start: new DateTime.local(2019, 3, 11),
                        end: new DateTime.local(2019, 4, 7),
                        visit: "1",
                        note: "I am a note",
                        complete: 70,
                        collapse: false,
                        actions: [],
                        fragments: [{
                            id: "bb",
                            status: TAG_ACTION_STATE_ACTIVE,
                            edit: false,
                            label: "Tasktito",
                            originalStart: new DateTime.local(2019, 3, 11),
                            originalEnd: new DateTime.local(2019, 3, 24),
                            start: new DateTime.local(2019, 3, 11),
                            end: new DateTime.local(2019, 3, 24),
                            visit: "1",
                            complete: 10,
                            collapse: false,
                            actions: [],
                            fragments: []
                        },
                        {
                            id: "b",
                            status: TAG_ACTION_STATE_ACTIVE,
                            edit: false,
                            label: "Taskorama",
                            originalStart: new DateTime.local(2019, 3, 11),
                            originalEnd: new DateTime.local(2019, 4, 4),
                            start: new DateTime.local(2019, 3, 11),
                            end: new DateTime.local(2019, 4, 4),
                            visit: "2",
                            complete: 10,
                            collapse: false,
                            actions: [],
                            fragments: []
                        },
                        {
                            id: "c",
                            status: TAG_ACTION_STATE_ACTIVE,
                            edit: false,
                            label: "Yo boy",
                            originalStart: new DateTime.local(2019, 3, 15),
                            originalEnd:new DateTime.local(2019, 4, 4),
                            start: new DateTime.local(2019, 3, 15),
                            end:new DateTime.local(2019, 4, 4),
                            visit: "3",
                            complete: 75,
                            collapse: false,
                            actions: [],
                            fragments: []
                        },
                        {
                            id: "d",
                            status: TAG_ACTION_STATE_PLANNED,
                            edit: false,
                            label: "Another",
                            originalStart: new DateTime.local(2019, 4, 1),
                            originalEnd:new DateTime.local(2019, 4, 26),
                            start: new DateTime.local(2019, 4, 1),
                            end:new DateTime.local(2019, 4, 26),
                            visit: "4",
                            complete: 0,
                            collapse: false,
                            actions: [],
                            fragments: []
                        }]
                    },
                    {
                        id: "a2",
                        status: TAG_ACTION_STATE_PLANNED,
                        edit: false,
                        label: "Second top",
                        originalStart: new DateTime.local(2019, 4, 8),
                        originalEnd: new DateTime.local(2019, 4, 25),
                        start: new DateTime.local(2019, 4, 8),
                        end: new DateTime.local(2019, 4, 25),
                        visit: "1",
                        complete: 0,
                        collapse: false,
                        actions: [],
                        fragments: [{
                            id: "b2",
                            status: TAG_ACTION_STATE_PLANNED,
                            edit: false,
                            label: "Task the mask",
                            originalStart: new DateTime.local(2019, 4, 8),
                            originalEnd: new DateTime.local(2019, 4, 14),
                            start: new DateTime.local(2019, 4, 8),
                            end: new DateTime.local(2019, 4, 14),
                            visit: "1",
                            complete: 0,
                            collapse: false,
                            actions: [],
                            fragments: []
                        },
                        {
                            id: "c2",
                            status: TAG_ACTION_STATE_PLANNED,
                            edit: false,
                            label: "Yo task thing",
                            originalStart: new DateTime.local(2019, 4, 12),
                            originalEnd:new DateTime.local(2019, 4, 15),
                            start: new DateTime.local(2019, 4, 12),
                            end:new DateTime.local(2019, 4, 15),
                            visit: "2",
                            complete: 0,
                            collapse: false,
                            actions: [],
                            fragments: []
                        },
                        {
                            id: "d2",
                            status: TAG_ACTION_STATE_PLANNED,
                            edit: false,
                            label: "Hey thing ",
                            originalStart: new DateTime.local(2019, 4, 15),
                            originalEnd:new DateTime.local(2019, 4, 25),
                            start: new DateTime.local(2019, 4, 15),
                            end:new DateTime.local(2019, 4, 25),
                            visit: "3",
                            complete: 0,
                            collapse: false,
                            actions: [],
                            fragments: [{
                                id: "b3",
                                status: TAG_ACTION_STATE_PLANNED,
                                edit: false,
                                label: "Taskorama",
                                originalStart: new DateTime.local(2019, 4, 18),
                                originalEnd: new DateTime.local(2019, 4, 21),
                                start: new DateTime.local(2019, 4, 18),
                                end: new DateTime.local(2019, 4, 21),
                                visit: "1",
                                complete: 0,
                                collapse: false,
                                actions: [],
                                fragments: [{
                                    id: "c4",
                                    status: TAG_ACTION_STATE_PLANNED,
                                    edit: false,
                                    label: "Taskorama",
                                    originalStart: new DateTime.local(2019, 4, 18),
                                    originalEnd: new DateTime.local(2019, 4, 23),
                                    start: new DateTime.local(2019, 4, 18),
                                    end: new DateTime.local(2019, 4, 23),
                                    visit: "1",
                                    complete: 0,
                                    collapse: false,
                                    actions: [],
                                    fragments: []
                                },
                                {
                                    id: "d4",
                                    status: TAG_ACTION_STATE_PLANNED,
                                    edit: false,
                                    label: "Another",
                                    originalStart: new DateTime.local(2019, 4, 20),
                                    originalEnd: new DateTime.local(2019, 4, 26),
                                    start: new DateTime.local(2019, 4, 20),
                                    end:new DateTime.local(2019, 4, 26),
                                    visit: "2",
                                    complete: 0,
                                    collapse: false,
                                    actions: [],
                                    fragments: []
                                }]
                            },
                            {
                                id: "c3",
                                status: TAG_ACTION_STATE_PLANNED,
                                edit: false,
                                label: "Yo boy",
                                originalStart: new DateTime.local(2019, 4, 23),
                                originalEnd: new DateTime.local(2019, 4, 25),
                                start: new DateTime.local(2019, 4, 23),
                                end: new DateTime.local(2019, 4, 25),
                                visit: "2",
                                complete: 0,
                                collapse: false,
                                actions: [],
                                fragments: []
                            },
                            {
                                id: "d3",
                                status: TAG_ACTION_STATE_PLANNED,
                                edit: false,
                                label: "Another",
                                originalStart: new DateTime.local(2019, 4, 25),
                                originalEnd: new DateTime.local(2019, 4, 26),
                                start: new DateTime.local(2019, 4, 25),
                                end: new DateTime.local(2019, 4, 26),
                                visit: "3",
                                complete: 0,
                                collapse: false,
                                actions: [],
                                fragments: []
                            }]
                        }]
                    },
                ],
                dependencies: [{
                    parent: "a",
                    child: "a2",
                },{
                    parent: "a",
                    child: "c2",
                },{
                    parent: "a2",
                    child: "d3",
                },{
                    parent: "a",
                    child: "d4",
                },{
                    parent: "c3",
                    child: "d3",
                }],
                intervalData: [],
                displayApproveReject: true
            }
        });
    }
    onGetTimelineLoad(data, that)
    {
        console.log("LOAD TIMELINE", data.timeline);
        console.log("TIMELINE", that.state.context.timeline);
        that.setState((prevState, props) => {
          return {
              context: props.context,
              matrix: [1, 0, 0, 1, 0, 0],
              dragging: false,
              scrolling: false,
              resizing: false,
              lastScrolling: 0,
              firstRender: undefined,
              editFormVisible: false,
              dragOffset: {
                  x: 0,
                  y: 0
              },
              contentHeight: 0,
              viewOffset: 0,
              viewTop: 0,
              viewBottom: 0,
              timeline: data.timeline,
              displayApproveReject: data.displayApproveReject,
              selectedLevels: that.getSelectableLevels(data.timeline.lines)
          };
        })

        console.log("LOAD TIMELINE FINISHED");
//        const canvasHeightPx        = that.measureLineHeights(data.timeline.lines, TAG_CANVAS_PADDING_HEIGHT)
//        that.setState({canvasHeightPx: canvasHeightPx});
        that.offScreen()
        that.moveToday()
    }
    getSelectableLevels(fragments)
    {
        let levels = []
        for(let i = 0; i < fragments.length; i++)
        {
            if(fragments[i].level > levels.length)
            {
                levels = []
                for(let j = 0; j < fragments[i].level; j++)
                {
                    levels.push(j + 1)
                }
            }
            if(fragments[i].fragments.length > 0)
            {
                levels = this.getSelectableLevels(fragments[i].fragments)
            }
        }
        return levels
    }
    updateLevelDisplay(fragments, selectedLevels)
    {
        for(let i = 0; i < fragments.length; i++)
        {
            if( selectedLevels.indexOf(fragments[i].level) > -1)
                fragments[i].collapse = false;
            else
                fragments[i].collapse = true;

            if(fragments[i].fragments.length > 0)
            {
                this.updateLevelDisplay(fragments[i].fragments, selectedLevels)
            }
        }
    }
    displayLevel(level)
    {
        return selectedLevels.indexOf(level) > -1 ? true : false
    }
    onCollapse(id)
    {
        const { lines } = this.state.timeline
        for(let i = 0; i < lines.length; i++)
        {
            if(lines[i].parentId == id) lines[i].collapse = !lines[i].collapse
        }
        this.setState({ timeline: { ...this.state.timeline, lines} });
    }
    onMessage(d, that)
    {
        message.destroy()
        switch(d.action)
        {
            case "SUCCESS": message.success(d.text);    break;
            case "INFO":    message.info(d.text);       break;
            case "ERROR":   message.error(d.text);      break;
        }
    }
    getLine(fragments, id)
    {
        let line = undefined
        for(let i = 0; i < fragments.length; i++)
        {
            if(fragments[i].id == id) line = fragments[i]
            if(fragments[i].fragments.length > 0)
            {
                let children = this.getLine(fragments[i].fragments, id)
                if(children != undefined) line = children
            }
        }
        return line
    }
    getParentLine(fragments, id, prospect)
    {
        let parent = undefined
        for(let i = 0; i < fragments.length; i++)
        {
            if(fragments[i].id == id)
            {
                if(prospect != undefined)
                    parent = prospect
                return parent
            }
            if(fragments[i].fragments.length > 0)
            {
                let tempParent = fragments[i]
                let parentTest = this.getParentLine(fragments[i].fragments, id, tempParent)
                if(parentTest != undefined)
                {
                    parent = parentTest
                    return parent
                }
            }
        }
        return parent
    }
    changeStart(e)
    {
        e.stopPropagation();
    }
    resizing()
    {
        this.setState({resizing: true});
        this.offScreen()
        setTimeout(
            function()
            {
                const canvasHeightPx        = this.measureLineHeights(this.state.timeline.lines, TAG_CANVAS_PADDING_HEIGHT)
                this.setState({resizing: false, canvasHeightPx: canvasHeightPx});
            }
            .bind(this),
            100
        );
    }
    componentDidMount()
    {
        window.addEventListener("resize", this.resizing.bind(this));
        // window.addEventListener('scroll', this.offScreen.bind(this));

        this.state.context.push("TIMELINE_READY");
        // this.state.context.push("TIMELINE_TEST_READY");
    }
    componentWillUnmount()
    {
        window.removeEventListener("resize", this.resizing.bind(this));
        // window.removeEventListener('scroll', this.offScreen.bind(this));
    }
    getData()
    {
        console.log("PROJECT GET DATA");
    }
    getHeaderHeight()
    {
        return TAG_HEADER_HEIGHT + TAG_INTERVAL_HEADER_HEIGHT
    }
    left(scale, line)
    {
        let left = 0
        if(!this.state.timeline.start) return left

        switch (scale) {
            default:
            case TAG_SCALE_HOUR:    if(line.diff_left_TAG_SCALE_HOUR == undefined)   line.diff_left_TAG_SCALE_HOUR    = line.start.diff(this.state.timeline.start, 'hours').hours; break;
            case TAG_SCALE_DAY:     if(line.diff_left_TAG_SCALE_DAY == undefined)    line.diff_left_TAG_SCALE_DAY     = line.start.diff(this.state.timeline.start, 'days').days; break;
            case TAG_SCALE_WEEK:    if(line.diff_left_TAG_SCALE_WEEK == undefined)   line.diff_left_TAG_SCALE_WEEK    = line.start.diff(this.state.timeline.start, 'weeks').weeks; break;
            case TAG_SCALE_MONTH:   if(line.diff_left_TAG_SCALE_MONTH == undefined)  line.diff_left_TAG_SCALE_MONTH   = line.start.diff(this.state.timeline.start, 'months').months; break;
            case TAG_SCALE_QUARTER: if(line.diff_left_TAG_SCALE_QUARTER == undefined)line.diff_left_TAG_SCALE_QUARTER = (line.start.diff(this.state.timeline.start, 'months').months/3); break;
            case TAG_SCALE_YEAR:    if(line.diff_left_TAG_SCALE_YEAR == undefined)   line.diff_left_TAG_SCALE_YEAR    = line.start.diff(this.state.timeline.start, 'years').years; break;
        }

        switch (scale) {
            default:
            case TAG_SCALE_HOUR:    left = (line.diff_left_TAG_SCALE_HOUR    * TAG_INTERVAL_WIDTH) + (this.state.dragOffset.x + this.state.viewOffset);    break;
            case TAG_SCALE_DAY:     left = (line.diff_left_TAG_SCALE_DAY     * TAG_INTERVAL_WIDTH) + (this.state.dragOffset.x + this.state.viewOffset);    break;
            case TAG_SCALE_WEEK:    left = (line.diff_left_TAG_SCALE_WEEK    * TAG_INTERVAL_WIDTH) + (this.state.dragOffset.x + this.state.viewOffset);    break;
            case TAG_SCALE_MONTH:   left = (line.diff_left_TAG_SCALE_MONTH   * TAG_INTERVAL_WIDTH) + (this.state.dragOffset.x + this.state.viewOffset);    break;
            case TAG_SCALE_QUARTER: left = (line.diff_left_TAG_SCALE_QUARTER * TAG_INTERVAL_WIDTH) + (this.state.dragOffset.x + this.state.viewOffset);    break;
            case TAG_SCALE_YEAR:    left = (line.diff_left_TAG_SCALE_YEAR    * TAG_INTERVAL_WIDTH) + (this.state.dragOffset.x + this.state.viewOffset);    break;
        }
        return left
    }
    width(scale, line)
    {
        let width = 0
        if(!this.state.timeline.start) return width

        switch (scale) {
            default:
            case TAG_SCALE_HOUR:    if(line.diff_width_TAG_SCALE_HOUR == undefined)     line.diff_width_TAG_SCALE_HOUR      = (line.start.diff(line.end, 'hours').hours);           break;
            case TAG_SCALE_DAY:     if(line.diff_width_TAG_SCALE_DAY == undefined)      line.diff_width_TAG_SCALE_DAY       = (line.start.diff(line.end, 'days').days);             break;
            case TAG_SCALE_WEEK:    if(line.diff_width_TAG_SCALE_WEEK == undefined)     line.diff_width_TAG_SCALE_WEEK      = (line.start.diff(line.end, 'weeks').weeks);           break;
            case TAG_SCALE_MONTH:   if(line.diff_width_TAG_SCALE_MONTH == undefined)    line.diff_width_TAG_SCALE_MONTH     = (line.start.diff(line.end, 'months').months);         break;
            case TAG_SCALE_QUARTER: if(line.diff_width_TAG_SCALE_QUARTER == undefined)  line.diff_width_TAG_SCALE_QUARTER   = ((line.start.diff(line.end, 'months').months) / 3);   break;
            case TAG_SCALE_YEAR:    if(line.diff_width_TAG_SCALE_YEAR == undefined)     line.diff_width_TAG_SCALE_YEAR      = (line.start.diff(line.end, 'years').years);           break;
        }

        switch (scale) {
            default:
            case TAG_SCALE_HOUR:    width = (line.diff_width_TAG_SCALE_HOUR         * TAG_INTERVAL_WIDTH);  break;
            case TAG_SCALE_DAY:     width = (line.diff_width_TAG_SCALE_DAY          * TAG_INTERVAL_WIDTH);  break;
            case TAG_SCALE_WEEK:    width = (line.diff_width_TAG_SCALE_WEEK         * TAG_INTERVAL_WIDTH);  break;
            case TAG_SCALE_MONTH:   width = (line.diff_width_TAG_SCALE_MONTH        * TAG_INTERVAL_WIDTH);  break;
            case TAG_SCALE_QUARTER:    width = (line.diff_width_TAG_SCALE_QUARTER   * TAG_INTERVAL_WIDTH);  break;
            case TAG_SCALE_YEAR:    width = (line.diff_width_TAG_SCALE_YEAR         * TAG_INTERVAL_WIDTH);  break;
        }
        return Math.max(TAG_LINE_MIN_WIDTH, Math.abs(width))
    }
    today(scale)
    {
        let today       = new Date()
        const year      = today.getFullYear()
        const month     = today.getMonth() + 1
        const quarter   = Math.floor(today.getMonth() / 3)
        const day       = today.getDate()
        const hour      = today.getHours()
        const minute    = today.getMinutes()
        const second    = today.getSeconds()

        let todayDate = new DateTime.local(year, month, day, hour, minute, second)
        return todayDate
    }
    drawToday(scale)
    {
        let left = 0
        let todayDate = this.today(scale)
        switch (scale) {
            default:
            case TAG_SCALE_HOUR:    left = (todayDate.diff(this.state.timeline.start, 'hours').hours            * TAG_INTERVAL_WIDTH) + (this.state.dragOffset.x + this.state.viewOffset);  break;
            case TAG_SCALE_DAY:     left = (todayDate.diff(this.state.timeline.start, 'days').days              * TAG_INTERVAL_WIDTH) + (this.state.dragOffset.x + this.state.viewOffset);  break;
            case TAG_SCALE_WEEK:    left = (todayDate.diff(this.state.timeline.start, 'weeks').weeks            * TAG_INTERVAL_WIDTH) + (this.state.dragOffset.x + this.state.viewOffset);  break;
            case TAG_SCALE_MONTH:   left = (todayDate.diff(this.state.timeline.start, 'months').months          * TAG_INTERVAL_WIDTH) + (this.state.dragOffset.x + this.state.viewOffset);  break;
            case TAG_SCALE_QUARTER: left = ((todayDate.diff(this.state.timeline.start, 'months').months / 3)    * TAG_INTERVAL_WIDTH) + (this.state.dragOffset.x + this.state.viewOffset);  break;
            case TAG_SCALE_YEAR:    left = (todayDate.diff(this.state.timeline.start, 'years').years            * TAG_INTERVAL_WIDTH) + (this.state.dragOffset.x + this.state.viewOffset);  break;
        }
        return parseInt(left)
    }
    normalisedVerticalOffset()
    {
        if(this.props.size.height > this.state.contentHeight)
            return 0
        else
            return this.state.dragOffset.y;
    }
    measureLineHeights(fragments, top)
    {
        const normalTop     = this.normalisedVerticalOffset()
        const normalBottom  = normalTop + this.state.contentHeight

        fragments.map((l, index) =>
        {
            l.visible = true
            //const parentLine = this.getParentLine(this.state.timeline.lines, l.id)
            //if(parentLine != undefined)
            //{
                // if(!parentLine.collapse)
                if(!l.collapse)
                    top += TAG_LINE_HEADER_HEIGHT
                else
                    l.visible = false
            //}
            //else
            //    top += TAG_LINE_HEADER_HEIGHT

            l.top = top + normalTop;

            if(top + normalTop + (TAG_LINE_HEADER_HEIGHT * 2) > this.state.viewTop && top + normalTop + TAG_LINE_HEADER_HEIGHT< this.state.viewBottom)
            {
                l.left = this.left(this.state.timeline.scale, l)
                l.width = this.width(this.state.timeline.scale, l)
            }

            //l.right = l.left + l.width
            // if(top < normalBottom)
                top = this.measureLineHeights(l.fragments, top)
        })
        return top
    }
    renderDependency(key, pl, cl)
    {
        // console.log('renderDependency', key, pl, cl)

        if(pl == undefined || cl == undefined) return (<div></div>)

        const normalTop     = this.normalisedVerticalOffset()
        const normalBottom  = normalTop + this.state.contentHeight

        let left, top, width, height
        let p               = ""
        let pStrokeColor    = "rgba(0,0,0,0.5)"
        let pStrokeWidth    = "1"
        let pStrokeDash     = "1,2"

        if( pl.left         == undefined ||
            pl.right        == undefined ||
            pl.width        == undefined ||
            pl.top          == undefined ||
            cl.left         == undefined ||
            cl.right        == undefined ||
            cl.width        == undefined ||
            cl.top          == undefined
        ) return

        pl.left = this.left(this.state.timeline.scale, pl)
        pl.width = this.width(this.state.timeline.scale, pl)
        cl.left = this.left(this.state.timeline.scale, cl)
        cl.width = this.width(this.state.timeline.scale, cl)


        left                = (pl.left < cl.left ? pl.left : cl.left) - 15
        top                 = (pl.top < cl.top ? pl.top : cl.top)
        width               = Math.abs((pl.right > cl.right ? pl.right : cl.right) - left) + 1000
        height              = Math.abs(pl.top - cl.top) + 10 + 55

        const bottom        = top + height

        if(
            (top + (TAG_LINE_HEADER_HEIGHT * 2) < this.state.viewTop && bottom + TAG_LINE_HEADER_HEIGHT > this.state.viewBottom)
        )
            return (<div></div>)

        let fromX           = Math.abs(pl.width) + 10 + (cl.left < pl.left ? Math.abs(pl.left - cl.left) : 0) + 10
        let fromY           = pl.top - top + 15
        let toX             = cl.left - left - 5
        let toY             = cl.top - top + 15
//        let midX            = fromX + ((toX - fromX) / 2) + 10
//        let midY            = fromY + ((toY - fromY) / 2)

//        let fromYC          = fromY + (fromX > toX ? 30 : 30)
//        let toYC            = toY - (fromX > toX ? 30 : 30)

        let fromYC          = (pl.top > cl.top ? fromY - 15 : fromY + 15)
        let toYC            = (pl.top > cl.top ? toY + 15 : toY - 15)

        if(fromX - 15 > toX)
        {
            pStrokeColor    = "rgba(255,0,0,1)"
            pStrokeWidth    = "1"
            pStrokeDash     = ""
        }

        //  p = "M" + fromX + " " + fromY + " Q " + (fromX + 20) + " " + fromY + ", " + midX + " " + midY + " T " + toX + " " + toY
        p = "M" + fromX + " " + fromY + " C " + (fromX + 30) + " " + fromYC + " " + (toX - 30) + " " + toYC + " " + toX + " " + toY

        return(
            <svg key={pl.id + cl.id} className={tagLineDependency} width={width} height={height} style={{left: left, top: top}} xmlns="http://www.w3.org/2000/svg">
                <path d={p} stroke={pStrokeColor} strokeDasharray={pStrokeDash} strokeWidth={pStrokeColor} strokeLinecap="round" fill="transparent"/>
            </svg>
        )
    }
    drawDependencies()
    {
        return (
            this.state.timeline.dependencies.map((d, index) =>
                this.renderDependency(
                    d.parent + d.child,
                    this.getLine(this.state.timeline.lines, d.parent),
                    this.getLine(this.state.timeline.lines, d.child)
                )
            )
        )
    }
    drawCurves()
    {
        let curveGroup = []
        let max = 0
        let index = 0
        const { timeline } = this.state

        max = parseInt(timeline.curvesMax)

        this.state.timeline.legend.map((l, index) => {
            if(l.visible) curveGroup.push(this.drawCurve(l.daTimelineID, l.sequence, l.colour, l.label, max))
        })
        return curveGroup
    }
    drawCurve(timelineId, index, color = 'black', label = "Line", max = undefined)
    {
        const { width, height } = this.props.size
        const { viewTop, viewBottom, timeline, moveX, moveY } = this.state

        let dotX        = -100
        let dotY        = 0
        let leftDotX    = undefined
        let topDotY     = undefined
        let sPointX     = undefined
        let sPointY     = undefined

        if(timeline.curves[timelineId] == undefined) return (<div></div>)

        if(max == undefined)
            max = timeline.curves[timelineId + '_max']

        let poly = ''
        timeline.curves[timelineId].map((p, index) => {
            const left  = parseInt(this.left(this.state.timeline.scale, p))
            const top   = parseInt(CURVE_HEADER + ((1 - (p.daCumAmt / max)) * (height - CURVE_HEADER - CURVE_FOOTER)))
            const value = p.daCumAmt
            const start = p.start
            poly += left + ',' + top + ' '

            if(moveX != undefined && moveY != undefined)
            {
                if(leftDotX == undefined || Math.abs(moveX - left) < leftDotX) {
                    dotY = top - DOT_SCALE
                    leftDotX = Math.abs(moveX - left)
                    sPointX = {
                        value: value,
                        start: start
                    }
                }
                if(topDotY == undefined || Math.abs((moveY - CURVE_HEADER) - top) < topDotY) {
                    dotX = left - DOT_SCALE
                    topDotY = Math.abs((moveY - CURVE_HEADER) - top)
                    sPointY = {
                        value: value,
                        start: start
                    }
                }
            }
        })

        poly = poly.trim()

        let axis = []
        let multiple = ((Math.floor(max.toString().length / 3)) * 3)
        let unit = "1"

        for(let u = 0; u < multiple; u++)
        {
            unit += "0"
        }
        unit = parseInt(unit)

        for(let a = 0; a <= CURVE_AXIS_INTERVAL_COUNT; a++)
        {
            let label   = parseInt(((a / CURVE_AXIS_INTERVAL_COUNT) * max) / unit)
            let y       = (1 - (a / CURVE_AXIS_INTERVAL_COUNT)) * (height - CURVE_HEADER - CURVE_FOOTER) + CURVE_HEADER
            let left    = (width - CURVE_AXIS_INTERVAL_WIDTH) + 'px'

            axis.push(
                <div style={{top: y, position: 'absolute', left: left}}>{label}</div>
            )
        }
        axis.push(
            <div style={{position: 'absolute',
                top: (height - CURVE_HEADER - (CURVE_FOOTER/2)) + CURVE_HEADER,
                left: (width - CURVE_AXIS_INTERVAL_WIDTH) }}>$ M</div>
        )

        const hoverDotLeft  = (moveX - DOT_SCALE) //+ 'px'
        const hoverDotTop   = (moveY - CURVE_HEADER - DOT_SCALE) //+ 'px'

        let annotation = []

        if(sPointX != undefined && sPointY != undefined)
        {
            let dotValueX = ProjectUtility.money(sPointX.value)
            // let dotValueY = ProjectUtility.money(sPointY.value)
            let dotValueY = sPointY.start.toFormat('d-LLL-yy')
            let timeDiff = Math.abs(sPointX.start.diff(sPointY.start, 'days').days) + ' d'
            let sDeltaValue = ProjectUtility.money(Math.abs(sPointX.value - sPointY.value))

            const sDeltaValueTop = (Math.abs(hoverDotTop - dotY) / 2) + (dotY < hoverDotTop ? dotY : hoverDotTop)
            const sDeltaTimeLeft = (Math.abs(hoverDotLeft - dotX) / 2) + (dotX < hoverDotLeft ? dotX : hoverDotLeft)

            if(index == 0)
            {
                annotation.push(
                    <div>
                        <div className={dotDeltaValue} style={{left:sDeltaTimeLeft, top: hoverDotTop}}>{timeDiff}</div>
                    </div>
                )
                annotation.push(
                    <div>
                        <div className={dotDeltaValue} style={{left:hoverDotLeft, top: sDeltaValueTop}}>{sDeltaValue}</div>
                    </div>
                )
            }


            const offsetX   = index % 2 == 0 ? -70 : 75
            const alignX    = index % 2 == 0 ? 'right' : 'left'

            const offsetY   = index % 2 == 0 ? 40 : 0

            annotation.push(
                <div>
                    <div className={dot} style={{left:hoverDotLeft, top: dotY, backgroundColor: color}}></div>
                    <div className={dotValueVertical} style={{left:hoverDotLeft + offsetX, top: dotY, color: color, textAlign: alignX}}>{dotValueX}</div>
                </div>
            )
            annotation.push(
                <div>
                    <div className={dot} style={{left:dotX, top: hoverDotTop, backgroundColor: color}}></div>
                    <div className={dotValue} style={{left:dotX, top: hoverDotTop + offsetY, color: color}}>{dotValueY}</div>
                </div>
            )
        }
        else
        {
            annotation.push(<div></div>)
        }

        return (
            <div>
                {axis}
                <svg height={height} width={width} style={{zIndex: '200', position: 'absolute', pointerEvents: 'none'}}>
                  <polyline points={poly} style={{fill: 'none', stroke: color, strokeWidth: '1', }} />
                  Sorry, your browser does not support inline SVG.
                </svg>
                {annotation}
            </div>
        )
    }
    renderIntervalBackground(date, left, oddeven, scale)
    {
        const leftPx = Math.ceil(left) + 'px'
        let widthPx = TAG_INTERVAL_WIDTH + 1 + 'px'
        if(scale == TAG_SCALE_MONTH)
        {
            const monthSeconds = ProjectUtility.getSeconds(date, scale).toFixed(10)
            const ratio = monthSeconds / TAG_RELATIVE_MONTH_SECONDS
            widthPx = Math.ceil(ratio * TAG_INTERVAL_WIDTH) + 'px'
        }

        const className = tagInterval + ' ' + oddeven
        return (
            <div key={date} className={className} style={{left: leftPx, width: widthPx}}></div>
        )
    }
    renderIntervalLabel(date, left, scale)
    {
        const leftPx = Math.ceil(left) + 'px'
        let widthPx = TAG_INTERVAL_WIDTH + 1 + 'px'
        if(scale == TAG_SCALE_MONTH)
        {
            const monthSeconds = ProjectUtility.getSeconds(date, scale).toFixed(10)
            const ratio = monthSeconds / TAG_RELATIVE_MONTH_SECONDS
            widthPx = Math.ceil(ratio * TAG_INTERVAL_WIDTH) + 'px'
        }
        const className = tagIntervalInfo
        const label = date.toLocaleString(DateTime.DATE_FULL)//.toMillis()//.toLocaleString(DateTime.DATETIME_MED)
        return (
            <div key={date.toMillis()} className={className} style={{left: leftPx, width: widthPx}}>
                <div className={tagIntervalLabel}>{label}</div>
            </div>
        )
    }
    prepareInterval()
    {
        const t = this.state.timeline
        const deltaOffset = this.state.dragOffset.x - (Math.floor(this.state.dragOffset.x / TAG_INTERVAL_WIDTH) * TAG_INTERVAL_WIDTH)
        switch (t.scale) {
            default:
            case TAG_SCALE_HOUR:
                t.view = t.start.minus({hours: Math.floor(this.state.dragOffset.x / TAG_INTERVAL_WIDTH)})
                break;
            case TAG_SCALE_DAY:
                t.view = t.start.minus({days: Math.floor(this.state.dragOffset.x / TAG_INTERVAL_WIDTH)})
                break;
            case TAG_SCALE_WEEK:
                t.view = t.start.minus({weeks: Math.floor(this.state.dragOffset.x / TAG_INTERVAL_WIDTH)})
                break;
            case TAG_SCALE_MONTH:
                t.view = t.start.minus({months: Math.floor(this.state.dragOffset.x / TAG_INTERVAL_WIDTH)})
                break;
            case TAG_SCALE_QUARTER:
                t.view = t.start.minus({months: (3 * Math.floor(this.state.dragOffset.x / TAG_INTERVAL_WIDTH))})
                break;
            case TAG_SCALE_YEAR:
                t.view = t.start.minus({years: Math.floor(this.state.dragOffset.x / TAG_INTERVAL_WIDTH)})
                break;
        }
        for(let i = -TAG_INTERVAL_BUFFER; i < TAG_INTERVAL_BUFFER; i++)
        {
            let intervalDate = 0
            let left = 0
            let oddeven = odd
            switch (t.scale) {
                default:
                case TAG_SCALE_HOUR:
                    intervalDate = this.state.timeline.view.startOf('hour').plus({hours: i})
                    left = (intervalDate.diff(this.state.timeline.view, 'hours').hours * TAG_INTERVAL_WIDTH) + deltaOffset + this.state.viewOffset;
                    oddeven = parseInt(intervalDate.toFormat('H')) % 2 == 0 ? even : odd
                    break;
                case TAG_SCALE_DAY:
                    intervalDate = this.state.timeline.view.startOf('day').plus({days: i})
                    left = (intervalDate.diff(this.state.timeline.view, 'days').days * TAG_INTERVAL_WIDTH) + deltaOffset + this.state.viewOffset;
                    oddeven = intervalDate.ordinal % 2 == 0 ? even : odd
                    break;
                case TAG_SCALE_WEEK:
                    intervalDate = this.state.timeline.view.startOf('week').plus({weeks: i})
                    left = (intervalDate.diff(this.state.timeline.view, 'weeks').weeks * TAG_INTERVAL_WIDTH) + deltaOffset + this.state.viewOffset;
                    oddeven = intervalDate.weekNumber % 2 == 0 ? even : odd
                    break;
                case TAG_SCALE_MONTH:
                    intervalDate = this.state.timeline.view.startOf('month').plus({months: i})
                    left = ((intervalDate.diff(this.state.timeline.view, 'milliseconds').milliseconds / TAG_RELATIVE_MONTH_SECONDS) * TAG_INTERVAL_WIDTH) + deltaOffset + this.state.viewOffset;
                    oddeven = parseInt(intervalDate.toFormat('L')) % 2 == 0 ? even : odd
                    break;
                case TAG_SCALE_QUARTER:
                    intervalDate = this.state.timeline.view.startOf('year').plus({months: (Math.floor(i*3))})
                    left = ((intervalDate.diff(this.state.timeline.view, 'months').months / 3) * TAG_INTERVAL_WIDTH) + deltaOffset + this.state.viewOffset;
                    oddeven = parseInt(intervalDate.toFormat('q')) % 2 == 0 ? even : odd
                    break;
                case TAG_SCALE_YEAR:
                    intervalDate = this.state.timeline.view.startOf('year').plus({year: i})
                    left = (intervalDate.diff(this.state.timeline.view, 'years').years * TAG_INTERVAL_WIDTH) + deltaOffset + this.state.viewOffset;
                    oddeven = parseInt(intervalDate.toFormat('yyyy')) % 2 == 0 ? even : odd
                    break;
            }
            t.intervalData[i + TAG_INTERVAL_BUFFER] = {
                scale: t.scale,
                date: intervalDate,
                left,
                oddeven
            }
        }
    }
    drawIntervalLabel(t)
    {
        return (
            t.intervalData.map((_i) =>
                this.renderIntervalLabel(_i.date, _i.left, _i.scale)
            )
        )
    }
    drawIntervalBackground(t)
    {
        return (
            t.intervalData.map((_i) =>
                this.renderIntervalBackground(_i.date, _i.left, _i.oddeven, _i.scale)
            )
        )
    }
    onDragStart(e)
    {
        const startX = typeof e.clientX === 'undefined' ? e.changedTouches[0].clientX : e.clientX;
        const startY = typeof e.clientY === 'undefined' ? e.changedTouches[0].clientY : e.clientY;
        const state = {
            dragging: true,
            startX,
            startY,
        };

        e.stopPropagation();
        this.setState(state);
    }
    onDragMove(e)
    {
        const x = typeof e.clientX === 'undefined' ? e.changedTouches[0].clientX : e.clientX;
        const y = typeof e.clientY === 'undefined' ? e.changedTouches[0].clientY : e.clientY;

        if (!this.state.dragging)
        {
            this.setState({
                moveX: x,
                moveY: y,
            });
            return
        }

        const dx = x - this.state.startX;
        const dy = y - this.state.startY;
        this.state.dragOffset.x += dx;
        this.state.dragOffset.y += dy;

        if(this.state.contentHeight + this.state.dragOffset.y < this.props.size.height)
            this.state.dragOffset.y = -(this.state.contentHeight - this.props.size.height)
        if(this.state.dragOffset.y > 0) this.state.dragOffset.y = 0

        this.setState({
            startX: x,
            startY: y,
        });
    }
    onDragEnd()
    {
        this.setState({ dragging: false });
        this.state.context.push("TIMELINE_CANVAS_DRAG_END");
        this.state.context.push("TIMELINE_DRAG", {
            offset: this.state.dragOffset
        });
    }
    normalizeWheel(event)
    {
        var sX = 0, sY = 0,       // spinX, spinY
            pX = 0, pY = 0;       // pixelX, pixelY

        // Legacy
        if ('detail'      in event) { sY = event.detail; }
        if ('wheelDelta'  in event) { sY = -event.wheelDelta / 120; }
        if ('wheelDeltaY' in event) { sY = -event.wheelDeltaY / 120; }
        if ('wheelDeltaX' in event) { sX = -event.wheelDeltaX / 120; }

        // side scrolling on FF with DOMMouseScroll
        if ( 'axis' in event && event.axis === event.HORIZONTAL_AXIS )
        {
            sX = sY;
            sY = 0;
        }

        pX = sX * PIXEL_STEP;
        pY = sY * PIXEL_STEP;

        if ('deltaY' in event) { pY = event.deltaY; }
        if ('deltaX' in event) { pX = event.deltaX; }

        if ((pX || pY) && event.deltaMode) {
            if (event.deltaMode == 1) {          // delta in LINE units
                pX *= LINE_HEIGHT;
                pY *= LINE_HEIGHT;
            } else {                             // delta in PAGE units
                pX *= PAGE_HEIGHT;
                pY *= PAGE_HEIGHT;
            }
        }

        // Fall-back if spin cannot be determined
        if (pX && !sX) { sX = (pX < 1) ? -1 : 1; }
        if (pY && !sY) { sY = (pY < 1) ? -1 : 1; }

        return {
            spinX  : sX,
            spinY  : sY,
            pixelX : pX,
            pixelY : pY
        }
    }
    onWheel(e)
    {
        const selector          = e.target.className.replace(' ant-tooltip-open','')
        const onResourcesList   = e.target.className.indexOf('ant-select-dropdown-menu-item') > -1 ? true : false

        if(onResourcesList) return

        if(!this.state.scrolling)
        {
            this.setState({
                lastScrolling: Date.now(),
                scrolling: true,
                dragging: false
            })
        }


        /*
        if(typeof e.deltaX === 'undefined') return

        const dx = e.deltaX * -1
        const dy = e.deltaY * -1
        */

        let scroll      = this.normalizeWheel(e)
        let dx          = (scroll.pixelX * -1) + this.state.dragOffset.x
        let dy          = (scroll.pixelY * -1) + this.state.dragOffset.y

        if(this.state.contentHeight + dy < this.props.size.height)
            dy = -(this.state.contentHeight - this.props.size.height)
        if(dy > 0) dy = 0

        this.setState({
            // startX: x,
            // startY: y,
            dragOffset: {
                x: dx,
                y: dy
            }
        });

        // e.preventDefault();
        // e.stopPropagation();

        clearTimeout(scrollTimerId)
        scrollTimerId = setTimeout(
            function()
            {
                if(this.state.lastScrolling > 0 && Date.now() - this.state.lastScrolling >= 500)
                {
                    this.setState({scrolling: false});
                }
            }
            .bind(this),
            500
        );
    }
    showToday()
    {
        this.setState({
            firstRender: undefined
        })
    }
    moveToday()
    {
        const today         = this.today(this.state.timeline.scale)
        let left            = 0

        switch (this.state.timeline.scale) {
            default:
            case TAG_SCALE_HOUR:    left = (today.diff(this.state.timeline.start, 'hours').hours   * TAG_INTERVAL_WIDTH);    break;
            case TAG_SCALE_DAY:     left = (today.diff(this.state.timeline.start, 'days').days     * TAG_INTERVAL_WIDTH);    break;
            case TAG_SCALE_WEEK:    left = (today.diff(this.state.timeline.start, 'weeks').weeks   * TAG_INTERVAL_WIDTH);    break;
            case TAG_SCALE_MONTH:   left = (today.diff(this.state.timeline.start, 'months').months * TAG_INTERVAL_WIDTH);    break;
            case TAG_SCALE_QUARTER: left = ((today.diff(this.state.timeline.start, 'months').months / 3) * TAG_INTERVAL_WIDTH);    break;
            case TAG_SCALE_YEAR:    left = (today.diff(this.state.timeline.start, 'years').years   * TAG_INTERVAL_WIDTH);    break;
        }

        console.log("today", today);
        console.log("left", left);
        console.log("left * -1", left * -1);

        this.state.dragOffset.x = left * -1
        this.setState({
            firstRender: true
        })
        console.log(this.state.dragOffset);
        return this.state.dragOffset.x
    }
    zoomTo(newScale)
    {
        console.log("zoomFrom", newScale.target.value);
        console.log("zoomTo", newScale.target.value);

        this.adjustView(this.state.timeline.scale, newScale.target.value)
        this.setState({ timeline: { ...this.state.timeline, scale: newScale.target.value} });
        this.forceUpdate()
    }
    setViewOffset()
    {
        const viewOffset = parseInt(this.props.size.width / 2)
        this.state.viewOffset = viewOffset
    }
    pushAction(line, action)
    {
        line.actions.push(action)
        for(let i = 0; i < line.fragments.length; i++)
        {
            this.pushAction(line.fragments[i], action)
        }
    }
    adjustView(initialScale, newScale)
    {
        console.log("+++ initialScale", initialScale);
        console.log("+++ newScale", newScale);

        const fromSeconds           = ProjectUtility.getSeconds(this.state.timeline.start, initialScale)
        const toSeconds             = ProjectUtility.getSeconds(this.state.timeline.start, newScale)
        const direction             = Math.sign(fromSeconds-toSeconds)
        this.state.dragOffset.x    *= (fromSeconds/toSeconds)
        this.state.timeline.scale   = newScale
    }
    zoomLabel()
    {
        switch (this.state.timeline.scale)
        {
            case TAG_SCALE_YEAR:    return "YEAR";      break;
            case TAG_SCALE_QUARTER: return "QUARTER";   break;
            case TAG_SCALE_MONTH:   return "MONTH";     break;
            case TAG_SCALE_WEEK:    return "WEEK";      break;
            case TAG_SCALE_DAY:     return "DAY";       break;
            case TAG_SCALE_HOUR:    return "HOUR";      break;
            case TAG_SCALE_MINUTE:  return "MINUTE";    break;
            case TAG_SCALE_SECOND:  return "SECOND";    break;
            default:                return "SECOND";    break;
        }
    }
    offScreen()
    {
        // return
        if(this.state.timeline === undefined) return
        const rectConatiner = this.tagContainer.getBoundingClientRect();
        this.setState({
            viewTop: rectConatiner.top,
            viewBottom: rectConatiner.height,
        });
    }
    onLineUpdate()
    {
    //    console.log("onLineUpdate");
        /**
        //  NOTE(JP):   Potential dispatch point for data change
        //
        //  This is how we can update UI on Line drag (and redraw
        //  connected elementes, i.e. dependencies) however we may
        //  wish to hold server updates until changes are 'saved'
        //  or when a user action has finished, i.e. onDragEnd
        //
        //  For now, let's use forceUpdate to redraw dependencies
        **/
    //    this.forceUpdate()
    }
    onLineEdit()
    {
        this.showEditForm()
    }
    onActiveLine(id)
    {
        const rectConatiner = this.tagContainer.getBoundingClientRect();
        this.setState({
            timeline: {...this.state.timeline, activeLine: id}
        });
    }
    onChangeTop(id, newTop)
    {
        const line = this.getLine(this.state.timeline.lines, id)
        line.top = newTop
        console.log("newTop", line.top);
        console.log("line.instance", line.instance);

        if(line.instance != undefined)
        {
            const top = ReactDOM.findDOMNode(line.instance).getBoundingClientRect().top
            console.log("line top", top);

        }
        /*
        this.setState({
            timeline: {...this.state.timeline, activeLine: id}
        });
        */
    }
    showEditForm()
    {
        this.setState({
            editFormVisible: true,
        });
    }
    getChanges(fragments)
    {
        let changes = ""
        fragments.map((l, index) => {
            if(l.edit)
            {
                changes += 'bar_' + l.claimRoleId + '_' + l.visit   + '|'
                changes += String(l.originalStart.toISODate())      + '{'
                changes += String(l.start.toFormat('d-LLL-yy'))     + '}'
                changes += String(l.originalEnd.toISODate())        + '['
                changes += String(l.end.toFormat('d-LLL-yy'))       + ']('
                changes += String(l.complete)                       + '),'
            }
            if(l.fragments.length > 0) changes += this.getChanges(l.fragments)
        })
        return changes
    }
    onEditFormClose(reason = undefined)
    {
        this.state.context.push("TIMELINE_CANVAS_DRAG_END");
        this.setState({
            editFormVisible: false,
        });

        console.log("REASON", reason)
        if(!reason) return

        const changes = this.getChanges(this.state.timeline.lines)
        this.state.context.push("TIMELINE_UPDATE_REQUEST_EXTENSION", {
            reason,
            changes
        });
    }
    addRole()
    {
        this.state.context.push("TIMELINE_ADD_ROLE");
    }
    approveChanges()
    {
        this.state.context.push("TIMELINE_ACTION_CHANGES", {
            action: 'APPROVE'
        });
    }
    rejectChanges()
    {
        this.state.context.push("TIMELINE_ACTION_CHANGES", {
            action: 'REJECT'
        });
    }
    approveRejectButtons(t)
    {
        return (
            <span style={{ width: '100%'}}>
                <Tooltip placement="top" title="Approve requested changes">
                    <Tag color="#90c547" onClick={this.approveChanges.bind(t)}>Approve</Tag>
                </Tooltip>
                <Tooltip placement="top" title="Reject requested changes">
                    <Tag color="#e91c2c" onClick={this.rejectChanges.bind(t)}>Reject</Tag>
                </Tooltip>
            </span>
        )
    }
    handleLevelChange(tag, checked)
    {
        const { selectedLevels } = this.state;
        const nextSelectedLevels = checked ? [...selectedLevels, tag] : selectedLevels.filter(t => t !== tag);
        console.log('You are interested in: ', nextSelectedLevels);
        this.setState({ selectedLevels: nextSelectedLevels });
        this.updateLevelDisplay(this.state.timeline.lines, nextSelectedLevels)
    }
    handleCurveChange(line, checked)
    {
        let legend = this.state.timeline.legend
        legend.map((l, index) => {
            if(line.daTimelineID == l.daTimelineID)
                l.visible = !l.visible
        })
        console.log(legend)
        this.setState({ timeline: { ...this.state.timeline, legend} })
    }
    renderLevel(tag)
    {
        return tag > 0 ? true : false
    }
    toggleDependencies(checked)
    {
        this.setState({
            displayDependencies: !this.state.displayDependencies
        })
    }
    togglePlan(checked)
    {
        this.setState({
            displayPlan: !this.state.displayPlan,
            displayCurves: this.state.displayPlan
        })
    }
    populateResources(resources)
    {
        if(resources.length <= 0) return

        let resourceOptions = []
        for(let i = 0; i < resources.length; i++)
        {
            const r = resources[i]

            if(r.id != undefined && r.label != undefined)
            {
                resourceOptions.push(<Option key={r.id}>{r.label}</Option>)
            }
        }
        return resourceOptions
    }
    displayAll(fragments, collapse = false)
    {
        for(let i = 0; i < fragments.length; i++)
        {
            fragments[i].collapse = collapse;

            if(fragments[i].fragments.length > 0)
            {
                this.displayAll(fragments[i].fragments, collapse)
            }
        }
    }
    updateResourcesDisplay(fragments, selectedResources)
    {
        if(selectedResources == undefined || selectedResources == "")
        {
            this.displayAll(fragments, false)
            return
        }

        for(let i = 0; i < fragments.length; i++)
        {
            let match = false
            for(let r = 0; r < selectedResources.length; r++)
            {
                if(selectedResources[r].toString() == fragments[i].resourceId)
                    match = true
            }

            if(match)
                fragments[i].collapse = false;
            else
                fragments[i].collapse = true;

            if(fragments[i].fragments.length > 0)
            {
                this.updateResourcesDisplay(fragments[i].fragments, selectedResources)
            }
        }
    }
    changeResourceFilter(value)
    {
        this.updateResourcesDisplay(this.state.timeline.lines, value)
    }
    render()
    {
        const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />
        if(this.state.timeline === undefined) {
            return (
                <div className={centeredBox}>
                  <Spin tip="Loading timeline" indicator={antIcon} />
                </div>
            )
        }
        const canvasHeightPx        = this.measureLineHeights(this.state.timeline.lines, TAG_CANVAS_PADDING_HEIGHT)
        this.state.contentHeight    = canvasHeightPx + (this.state.editFormVisible ? TAG_EDIT_FORM_HEIGHT : 0) + TAG_CANVAS_PADDING_HEIGHT
        const intervals             = this.prepareInterval()
        const intervalLabels        = this.drawIntervalLabel(this.state.timeline)
        const intervalBackground    = this.drawIntervalBackground(this.state.timeline)
        const dependencies          = this.drawDependencies()
        const curves                = this.drawCurves()
        const todayPx               = parseInt(this.drawToday(this.state.timeline.scale)).toString( ) + 'px'
        const zoomLabel             = this.zoomLabel()
        const blockAnimation        = this.state.dragging || this.state.resizing || this.state.scrolling
        let animate = 'none'
        if(!blockAnimation && !this.state.dragging && !this.state.scrolling) animate = 'all 500ms ease-in'
        this.setViewOffset()
        if(this.state.firstRender === undefined) this.moveToday()
        const selectableLevels      = this.getSelectableLevels(this.state.timeline.lines)
        return (
            <div>
                <div className={container}
                    ref={(el) => this.tagContainer = el }
                    onMouseDown={this.onDragStart.bind(this)}
                    onTouchStart={this.onDragStart.bind(this)}
                    onMouseMove={this.onDragMove.bind(this)}
                    onTouchMove={this.onDragMove.bind(this)}
                    onMouseUp={this.onDragEnd.bind(this)}
                    onTouchEnd={this.onDragEnd.bind(this)}
                    onWheel={this.onWheel.bind(this)}
                >
                    <div className={tagIntervalContainer}
                        style={{height: canvasHeightPx}}
                    >
                        {intervalBackground}
                    </div>
                    <div className={tagCanvas}
                        ref={(el) => this.tagCanvas = el }
                        style={{height: canvasHeightPx}}
                    >
                        <Layout className={tagToolbarContainer} style={{backgroundColor: '#FFFFFF', zIndex: 10, minWidth: '240px', width: '100%', height: '40px'}}>
                            <Sider style={{marginLeft: '30px'}} width="380">
                                <div>
                                    <Tag color="#898989" onClick={this.addRole.bind(this)}>Add Role</Tag>
                                    {this.state.displayApproveReject && this.approveRejectButtons(this)}
                                    <Select
                                        className={tagResouceList}
                                        mode="tags"
                                        size="small"
                                        style={{ width: 160, marginRight: '10px' }}
                                        placeholder="Filter resources"
                                        onChange={this.changeResourceFilter.bind(this)}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        >
                                        {this.state.timeline.resources.map((r, index) =>
                                            <Option size="small" key={r.id}>{r.label}</Option>
                                        )}
                                      </Select>
                                    <Switch
                                      checkedChildren={<Icon type="link" size="small" />}
                                      unCheckedChildren={<Icon type="link" size="small" />}
                                      defaultChecked
                                      style={{marginRight: '10px'}}
                                      onChange={this.toggleDependencies.bind(this)}
                                    />
                                    <Switch
                                      checkedChildren={<Icon type="menu-unfold" size="small" />}
                                      unCheckedChildren={<Icon type="menu-unfold" size="small" />}
                                      defaultChecked
                                      style={{marginRight: '10px'}}
                                      onChange={this.togglePlan.bind(this)}
                                    />
                                </div>
                            </Sider>
                            <Content style={{textAlign: 'center', marginTop: '0px'}}>
                                <Tag color="#f50" onClick={this.moveToday.bind(this)}>Today</Tag>
                                <Radio.Group defaultValue={this.state.timeline.scale} size="small" buttonStyle="solid" onChange={this.zoomTo.bind(this)}>
                                  <Radio.Button value="TAG_SCALE_YEAR">Year</Radio.Button>
                                  <Radio.Button value="TAG_SCALE_QUARTER">Quarter</Radio.Button>
                                  <Radio.Button value="TAG_SCALE_MONTH">Month</Radio.Button>
                                  <Radio.Button value="TAG_SCALE_WEEK">Week</Radio.Button>
                                  <Radio.Button value="TAG_SCALE_DAY">Day</Radio.Button>
                                </Radio.Group>
                            </Content>
                            <Sider style={{margin: '0px', textAlign: 'right', width: '400px'}}>

                                {this.state.displayPlan &&
                                    selectableLevels.map(tag => {
                                        if(this.renderLevel(tag))
                                            return (
                                                <CheckableTag
                                                  key={tag}
                                                  checked={this.state.selectedLevels.indexOf(tag) > -1}
                                                  onChange={checked => this.handleLevelChange(tag, checked)}
                                                >
                                                  {tag}
                                                </CheckableTag>
                                            )
                                    })
                                }
                            </Sider>
                        </Layout>
                        <div className={tagIntervalLabelContainer}>
                            {intervalLabels}
                        </div>
                        <div className={tagToday}
                            style={{left: todayPx, transition: animate}}
                        ></div>
                        { this.state.displayPlan &&
                            !this.props.collapse &&
                                this.state.timeline.lines.map((l, index) =>
                                    <ProjectLine
                                        line={l}
                                        activeLine={this.state.timeline.activeLine}
                                        lines={this.state.timeline.lines}
                                        key={l.id}
                                        collapse={l.collapse}
                                        offset={this.state.dragOffset.x + this.state.viewOffset}
                                        blockAnimation={blockAnimation}
                                        start={this.state.timeline.start}
                                        scale={this.state.timeline.scale}
                                        viewTop={this.state.viewTop}
                                        viewBottom={this.state.viewBottom}
                                        dragOffset={this.state.dragOffset}
                                        onUpdate={this.onLineUpdate.bind(this)}
                                        onLineEdit={this.onLineEdit.bind(this)}
                                        onActiveLine={this.onActiveLine.bind(this)}
                                        onChangeTop={this.onChangeTop.bind(this)}
                                        onCollapse={this.onCollapse.bind(this)}
                                        moveX={this.state.moveX}
                                        moveY={this.state.moveY}
                                        context={this.state.context}
                                        today={this.today()}
                                        selectedLevels={this.state.selectedLevels}
                                    />
                            )
                        }
                        {this.state.displayPlan && this.state.displayDependencies && dependencies }
                        {this.state.displayCurves && curves}
                        {this.state.displayCurves &&
                            <div className={tagLegend}>
                            {this.state.timeline.legend.map((l, index) => {
                                const background = l.visible ? l.colour : 'rgba(0,0,0,0)'
                                return (
                                    <div className={tagLegendOption}>
                                        <CheckableTag
                                            key={index}
                                            style={{backgroundColor: background}}
                                            checked={l.visible}
                                            onChange={checked => this.handleCurveChange(l, checked)}
                                        >
                                            {l.label}
                                        </CheckableTag>
                                    </div>
                                )
                            })}
                            </div>
                        }
                    </div>
                </div>
                <ProjectEdit
                    onEditFormClose={this.onEditFormClose.bind(this)}
                    editFormVisible={this.state.editFormVisible}
                />
            </div>
        )
    }
}

const config    = { monitorHeight: true, monitorPosition: true };
const sizeMeHOC = sizeMe(config);
export default sizeMeHOC(Project)
