import {
    SET_AUTHENTICATED_USER,
    UNSET_AUTHENTICATED_USER,
    SET_USER_INFO,
    UNSET_USER_INFO,
} from '../actions/user'

export default function authenticatedUser (state = null, action) {
    switch (action.type) {
        case SET_AUTHENTICATED_USER :
            return {
                ...state,
                user: action.user
            }
        case SET_USER_INFO :
            return {
                ...state,
                info: action.info
            }
        case UNSET_USER_INFO :
            return {
                ...state,
                info: null
            }
        case UNSET_AUTHENTICATED_USER :
            return null
        default :
            return state
    }
}
