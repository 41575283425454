import React from 'react'
import { connect } from 'react-redux'
import { Home, Project } from 'components'
import { setCurrentClaim, handleGetUserInfo } from 'actions'

class HomeContainer extends React.Component {
  componentWillMount () {
      this.props.dispatch(setCurrentClaim(null))
      if(!this.props.user.info)
      {
          //this.props.dispatch(handleGetUserInfo(this.props.token, this.props.user.user.LoginID))
      }
  }
  render () {
    return (
      <Home />
    )
  }
}

export default connect((state) => ({
    token: state.session,
    user: state.authenticatedUser
}))(HomeContainer)
