import { combineReducers } from 'redux'
import session from './session'
import authenticatedUser from './user'
import claim from './claim'
import timeline from './timeline'

export default combineReducers({
    session,
    authenticatedUser,
    claim,
    timeline,
})
