import {
    setAuthenticatedUser,
    handleGetUserInfo,
    unsetAuthenticatedUser
} from './user'
import { setSession, unsetSession } from './session'
import { setCurrentClaim } from './claim'

export function handleRegistration (email, pw, first, last = undefined) {
    return (dispatch) => {
        return fetch('/api/register', {
                method: 'POST',
                body: JSON.stringify({email, pw, first, last}),
                headers: { 'Content-Type': 'application/json' }
            })
            .then((res) => res.json())
    }
}

export function handleAuthentication (email, pw) {
    return (dispatch) => {
        return fetch('/api/authenticate', {
                method: 'POST',
                body: JSON.stringify({email, pw}),
                headers: { 'Content-Type': 'application/json' }
            })
            .then((res) => res.json())
            .then(({result, message, token, user}) => {
                if(result === 'SUCCESS')
                {
                    dispatch(setSession(token))
                    dispatch(setAuthenticatedUser(user))
                }
                else
                {
                    return {
                        result,
                        message
                    }
                }
            })
    }
}

export function handleSignOut () {
    return (dispatch, getState) => {
        //console.log('Sign out ...', getState().session)
        dispatch(unsetSession())
        dispatch(unsetAuthenticatedUser())
    }
}
