import React, { PropTypes } from 'react'
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import { FlowItem } from 'components'
import { container, title, slogan, box, withSpace, withoutSpace, boxWithoutSpace, chatBubbleTo, chatBubbleFrom } from './styles.css'
import { Card, Icon, Avatar, Row, Col, Layout, Collapse, Carousel, Steps } from 'antd';
import sizeMe from 'react-sizeme'
import { SpringGrid, measureItems, makeResponsive, layout } from 'react-stonecutter';
import { ChatFeed, Message } from 'react-chat-ui'
import { Map } from 'components'

const { Header, Footer, Sider, Content } = Layout;
const Panel = Collapse.Panel;
const Step = Steps.Step;

const Grid = measureItems(SpringGrid, {
  maxWidth: 1920,
  minPadding: 10,
  columnWidth: 320,
  measureImages: true
});
const GridStyles = {
    listStyle: 'none',
    display: 'flex'
}

const FlowAnchorStyles = {
    backgroundColor: '#f6f6f6'
}

const ChatBubbleMessageStyles = {
    margin: 0
}
const customBubble = props => (
    <div>
        {props.message.messageType === 'chat' &&
            <div className={props.message.id ? chatBubbleFrom : chatBubbleTo }>
            <p style={ChatBubbleMessageStyles}>{`${props.message.senderName} ${props.message.id ? 'says' : 'said'}: ${
                props.message.message
            }`}</p>
            </div>
        }
        {props.message.messageType === 'photos' &&
            <Carousel autoplay>
                <div><h3>1 HUGH JP !</h3></div>
                <div><h3>2</h3></div>
                <div><h3>3</h3></div>
                <div><h3>4</h3></div>
              </Carousel>
        }
        {props.message.messageType === 'steps' &&
            <Steps current={1} status="error">
              <Step title="Finished" description="This is a description" />
              <Step title="In Process" description="This is a description" />
            </Steps>
        }
    </div>
);

class Claim extends React.Component {
  state = {
    response: 'Hi JP',
    key: 'tab1',
    noTitleKey: 'article',
    messages: [
        new FlowItem({
          id: 1,
          message: "I'm the recipient! (The person you're talking to)",
          senderName: "Bill",
          messageType: "chat"
        }),
        new FlowItem({
          id: 0,
          message: "I'm the recipient! (The person you're talking to)",
          senderName: "JP",
          messageType: "chat"
        }),
        new FlowItem({
          id: 0,
          message: "I'm the recipient! (The person you're talking to)",
          senderName: "JP",
          messageType: "chat"
        }),
        new FlowItem({
            id: 1,
            message: "I'm you -- the blue bubble!",
            senderName: "Carl",
            messageType: "chat"
        }),
        new FlowItem({
            id: 2,
            message: "Photo test",
            senderName: "Carl",
            messageType: "photos"
        }),
        new FlowItem({
            id: 3,
            message: "Steps",
            senderName: "",
            messageType: "steps"
        }),
    ],
    useCustomBubble: true,
  }
  clamp = (num, min, max) => {
      return num <= min ? min : num >= max ? max : num;
  }
  componentDidMount () {
      console.log("Claim ID:", this.props.claimId)
  }
  contact(address) {
      console.log('Contact', address);
  }
  render () {
        const { columnWidth, gutterWidth, size } = this.props;
        let columns;
        let minPadding = 5;
        let flowTop = size.position.top;
        columns = this.clamp(Math.floor((((size.width - minPadding) / (315))) / 2), 1, 6);

        let c = this.props.claim[this.props.claim.currentClaim]
        let c_direction = []
        if(c !== undefined)
        {
            c_direction = c.details.Directions.split(/(?:\r\n|\r|\n)/g)
            console.log(c_direction);
        }

    return (
      <div className={container}>
        <Row gutter={3} type="flex" justify="center" align="top">
            <Col span={12}>
            {c &&
                <Grid
                columns={columns}
                component="ul"
                columnWidth={310}
                gutterWidth={6}
                gutterHeight={6}
                layout={layout.pinterest}
                duration={250}
                easing="ease-out"
                style={GridStyles}
                >
                    <li span={8}>
                      <div className={boxWithoutSpace}>
                        <div className={withSpace}>
                        <Icon type="edit" style={{ float: 'right' }}/>

                      <span className="info-small">{c.details.ClaimRef}</span>
                      <span className="info-focus info-gutter">{c.details.PHfullname}</span>
                      <span className="info-standard info-gutter">
                        {!!c.details.PHPhoneMobile && (
                          <span><a onClick={event => {this.contact(c.details.PHPhoneMobile)}}>{c.details.PHPhoneMobile} <Icon type="phone" /></a><br/></span>
                        )}
                        {!!c.details.PHPhoneHome && (
                            <span><a className="info-standard" onClick={event => {this.contact(c.details.PHPhoneHome)}}>{c.details.PHPhoneHome} <Icon type="phone" /></a><br/></span>
                        )}
                        {!!c.details.PHPhoneWork && (
                            <span><a className="info-standard" onClick={event => {this.contact(c.details.PHPhoneWork)}}>{c.details.PHPhoneWork} <Icon type="phone" /></a><br/></span>
                        )}
                        {!!c.details.PHemail && (
                            <span><a className="info-standard" onClick={event => {this.contact(c.details.PHemail)}}>{c.details.PHemail} <Icon type="mail" /></a><br/></span>
                        )}
                      </span>
                      <span className="info-headspace"><a className="info-standard" onClick={event => {this.map(c.details.RiskLat, c.details.RiskLng)}}>
                        {!!c.details.RiskAddress1 && ( <span>{c.details.RiskAddress1}<br /></span> )}
                        {!!c.details.RiskAddress2 && ( <span>{c.details.RiskAddress2}<br /></span> )}
                        {!!c.details.RiskAddressCity && ( <span>{c.details.RiskAddressCity} </span> )}
                        {!!c.details.RiskAddressState && ( <span>{c.details.RiskAddressState} </span> )}
                        {!!c.details.RiskAddressPostcode && ( <span>{c.details.RiskAddressPostcode} </span> )}
                      </a><br/></span>
                      </div>
                        {(!!c.details.RiskLat || !!c.details.RiskLng) && (
                            <Map
                                center={[this.clamp(c.details.RiskLat, -90, 90), c.details.RiskLng]}
                                target={{
                                        lat: this.clamp(c.details.RiskLat, -90, 90),
                                        lng: c.details.RiskLng,
                                        label: "Risk address"
                                    }} />
                        )}
                      </div>
                    </li>
                    <li span={8}>
                      <div className={box}>
                      <Icon type="edit" style={{ float: 'right' }}/>
                        <span className="info-standard info-gutter">
                        {!!c.details.LossType && (
                            <span className="info-focus info-gutter">{c.details.LossType}&nbsp;
                            {!!c.details.DALimitAmt && c.details.DALimitAmt != "NIL" && (
                                    <span className="info-less-focus">
                                        {c.details.DALimitAmt}
                                        <Icon type="edit"/>
                                        <br />
                                    </span>

                            )}
                            <br /></span>
                        )}
                        </span>
                        <span className="info-standard info-gutter">
                        {c && !!c.details.ProdShortName && ( <span>{c.details.ProdShortName}<br /></span> )}
                        {c && !!c.details.ClaimRef && ( <span className="info-small info-gutter">{c && c.details.ClaimRef}<br/></span> )}
                        </span>
                        <span className="info-standard">
                        {c_direction.length > 0 &&
                            c_direction.map((line) => (
                                <span key={line.toString()} className="info-standard">{line}<br /></span>
                            ))
                        }
                        </span>
                      </div>
                    </li>
                    <li span={8}>
                      <div className={box}>Test</div>
                    </li>
                    <li span={8}>
                      <div className={box}>This is some content</div>
                    </li>
                    <li span={8}>
                      <div className={box}>This is some content</div>
                    </li>
                    <li span={8}>
                      <div className={box}>This is some content</div>
                    </li>
                    <li span={8}>
                      <div className={box}>This is some content</div>
                    </li>
                    <li span={8}>
                      <div className={box}>This is some content</div>
                    </li>
                    <li span={8}>
                      <div className={box}>This is some content</div>
                    </li>
                </Grid>
            }
            </Col>
            <Col span={12}>
                <ChatFeed
                      chatBubble={this.state.useCustomBubble && customBubble}
                      messages={this.state.messages} // Boolean: list of message objects
                      isTyping={this.state.is_typing} // Boolean: is the recipient typing
                      hasInputField={false} // Boolean: use our input, or use your own
                      showSenderName={true} // show the name of the user who sent the message
                      bubblesCentered={false} //Boolean should the bubbles be centered in the feed?
                      // JSON: Custom bubble styles
                      bubbleStyles={
                        {
                          text: {
                            fontSize: 130
                          },
                          chatbubble: {
                            borderRadius: 170,
                            padding: 10,
                            backgroundImage: 'url(../../images/tail_blue.png)'
                          }
                        }
                      }
                    />
            </Col>
        </Row>
      </div>
    )
  }
}

const config = { monitorHeight: true, monitorPosition: true };
const sizeMeHOC = sizeMe(config);

export default connect((state) => ({
    user: state.authenticatedUser.user,
    token: state.authenticatedUser.token,
    claim: state.claim,
}))(sizeMeHOC(Claim))
