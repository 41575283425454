exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".styles__container___2VUaW{justify-content:center;align-items:center;padding:0}.styles__box___n1FTI{background-color:#2f3334;border:0 dashed #f6f6f6;margin:0;padding:0;min-width:230px;width:auto;text-align:center;display:block;color:#8a8a8a}", "", {"version":3,"sources":["/Users/johnmcmullan/Dropbox/Awesome/McMullan Consulting/Projects/Delta/PubSubReactTimeline/web/app/components/Home/styles.css"],"names":[],"mappings":"AAAA,2BACE,uBAAwB,AACxB,mBAAoB,AACpB,SAAY,CACb,AAED,qBACE,yBAA0B,AAC1B,wBAA2B,AAC3B,SAAY,AACZ,UAAa,AACb,gBAAiB,AACjB,WAAY,AACZ,kBAAmB,AACnB,cAAe,AACf,aAAe,CAChB","file":"styles.css","sourcesContent":[".container {\n  justify-content: center;\n  align-items: center;\n  padding:0px;\n}\n\n.box {\n  background-color: #2f3334;\n  border: 0px #f6f6f6 dashed;\n  margin: 0px;\n  padding: 0px;\n  min-width: 230px;\n  width: auto;\n  text-align: center;\n  display: block;\n  color: #8a8a8a;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "styles__container___2VUaW",
	"box": "styles__box___n1FTI"
};