import {
    TAG_SCALE_SECOND,
    TAG_SCALE_MINUTE,
    TAG_SCALE_HOUR,
    TAG_SCALE_DAY,
    TAG_SCALE_WEEK,
    TAG_SCALE_MONTH,
    TAG_SCALE_YEAR
} from './Project'

export const ProjectUtility = {
    getDistance: function( x1, y1, x2, y2 )
    {
        var xs = x2 - x1,
            ys = y2 - y1;

        xs *= xs;
        ys *= ys;

        return Math.sqrt( xs + ys );
    },
    getDaysInMonth: function(date)
    {
        return new Date(date.getYear(), date.getMonth() + 1, 0).getDate();
    },
    easeInOutBack: function (t, b, c, d, s)
    {
        /*
         *
         * TERMS OF USE - EASING EQUATIONS
         *
         * Open source under the BSD License.
         *
         * Copyright Â© 2001 Robert Penner
         * All rights reserved.
         *
         * Redistribution and use in source and binary forms, with or without modification,
         * are permitted provided that the following conditions are met:
         *
         * Redistributions of source code must retain the above copyright notice, this list of
         * conditions and the following disclaimer.
         * Redistributions in binary form must reproduce the above copyright notice, this list
         * of conditions and the following disclaimer in the documentation and/or other materials
         * provided with the distribution.
         *
         * Neither the name of the author nor the names of contributors may be used to endorse
         * or promote products derived from this software without specific prior written permission.
         *
         * THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS "AS IS" AND ANY
         * EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
         * MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL THE
         *  COPYRIGHT OWNER OR CONTRIBUTORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
         *  EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
         *  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED
         * AND ON ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING
         *  NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THIS SOFTWARE, EVEN IF ADVISED
         * OF THE POSSIBILITY OF SUCH DAMAGE.
         *
         */
        // t: current time, b: begInnIng value, c: change In value (end value), d: duration
		if (s == undefined) s = 1.70158;
		if ((t/=d/2) < 1) return c/2*(t*t*(((s*=(1.525))+1)*t - s)) + b;
		return c/2*((t-=2)*t*(((s*=(1.525))+1)*t + s) + 2) + b;
	},
    getSeconds: function(start, interval)
    {
        switch (interval) {
            default:
            case TAG_SCALE_SECOND:  return 1000; break;
            case TAG_SCALE_MINUTE:  return 1000 * 60; break;
            case TAG_SCALE_HOUR:    return 1000 * 60 * 60; break;
            case TAG_SCALE_DAY:     return 1000 * 60 * 60 * 24; break;
            case TAG_SCALE_WEEK:    return 1000 * 60 * 60 * 24 * 7; break;
            case TAG_SCALE_MONTH:   return 1000 * 60 * 60 * 24 * this.getDaysInMonth(start); break;
            case TAG_SCALE_YEAR:    return 1000 * 60 * 60 * 24 * 365; break;
        }
    },
    getIntervalStart: function(start, interval)
    {
        switch (interval) {
            default:
            case TAG_SCALE_SECOND:  return 1000; break;
            case TAG_SCALE_MINUTE:  return 1000 * 60; break;
            case TAG_SCALE_HOUR:    return 1000 * 60 * 60; break;
            case TAG_SCALE_DAY:     return 1000 * 60 * 60 * 24; break;
            case TAG_SCALE_WEEK:    return 1000 * 60 * 60 * 24 * 7; break;
            case TAG_SCALE_MONTH:   return 1000 * 60 * 60 * 24 * this.getDaysInMonth(start); break;
            case TAG_SCALE_YEAR:    return 1000 * 60 * 60 * 24 * 365; break;
        }
    },
    getAdjustedDate: function(date, interval, adjustment)
    {
        switch (interval) {
            default:
            case TAG_SCALE_SECOND:  return 1000; break;
            case TAG_SCALE_MINUTE:  return 1000 * 60; break;
            case TAG_SCALE_HOUR:    return 1000 * 60 * 60; break;
            case TAG_SCALE_DAY:     return 1000 * 60 * 60 * 24; break;
            case TAG_SCALE_WEEK:    return 1000 * 60 * 60 * 24 * 7; break;
            case TAG_SCALE_MONTH:   return 1000 * 60 * 60 * 24 * this.getDaysInMonth(start); break;
            case TAG_SCALE_YEAR:    return 1000 * 60 * 60 * 24 * 365; break;
        }
    }
}
