import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import sizeMe from 'react-sizeme'
import { DateTime } from 'luxon'
import Interval from 'luxon/src/interval.js'
import {
    container, odd, even, visible, hidden,
    tagCanvas, tagIntervalScale, tagIntervalContainer, tagInterval, tagIntervalLabelContainer, tagIntervalLabel, tagIntervalInfo,
    tagToolbar, tagToolbarContainer, tagLine, tagLineInfo,
    tagLineContent, tagLineContentContainer, tagLineContentHeader, tagLineContentDescendants, tagLineContentDescendantsHidden,
    tagLineContentBar, tagLineContentBarStart, tagLineContentBarEnd, tagLineContentBarProgress, tagLineContentBarProgressSlider,
    tagLineDependency
} from './styles.css'
import { ProjectUtility } from './Utility'
import { Layout, Button, Icon, Slider, Radio, Affix } from 'antd'
const ButtonGroup = Button.Group
const { Header, Footer, Sider, Content } = Layout;

export const TAG_SCALE_SECOND               = 'TAG_SCALE_SECOND'
export const TAG_SCALE_MINUTE               = 'TAG_SCALE_MINUTE'
export const TAG_SCALE_HOUR                 = 'TAG_SCALE_HOUR'
export const TAG_SCALE_DAY                  = 'TAG_SCALE_DAY'
export const TAG_SCALE_WEEK                 = 'TAG_SCALE_WEEK'
export const TAG_SCALE_MONTH                = 'TAG_SCALE_MONTH'
export const TAG_SCALE_YEAR                 = 'TAG_SCALE_YEAR'

export const TAG_ACTION_SCALE               = 'TAG_ACTION_SCALE'

export const TAG_ACTION_BAR_NONE            = 'TAG_ACTION_BAR_NONE'
export const TAG_ACTION_BAR_PROGRESS        = 'TAG_ACTION_BAR_PROGRESS'
export const TAG_ACTION_BAR_START           = 'TAG_ACTION_BAR_START'
export const TAG_ACTION_BAR_END             = 'TAG_ACTION_BAR_END'
export const TAG_ACTION_BAR_MOVE            = 'TAG_ACTION_BAR_MOVE'
export const TAG_ACTION_CANVAS_MOVE         = 'TAG_ACTION_CANVAS_MOVE'

export const TAG_START_DRAG_DISTANCE_MIN    = 3
export const TAG_INTERVAL_WIDTH             = 240
export const TAG_INTERVAL_BUFFER            = 12

export const TAG_HEADER_HEIGHT              = 30
export const TAG_INTERVAL_HEADER_HEIGHT     = 40
export const TAG_LINE_HEADER_HEIGHT         = 30

export const TAG_SCALE_DURATION             = 500

class ProjectLineDependency extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            draw: true,
            parentLine: this.props.parentLine,
            childLine: this.props.childLine,
        }
    }
    render()
    {
        const pl = this.props.parentLine
        const cl = this.props.childLine
        let left, top, width, height
        let p = ""

        if( pl.left     == undefined ||
            pl.right    == undefined ||
            pl.width    == undefined ||
            pl.top      == undefined ||
            cl.left     == undefined ||
            cl.right    == undefined ||
            cl.width    == undefined ||
            cl.top      == undefined
        ) this.state.draw = false
        else this.state.draw = true

        if(this.state.draw)
        {
            left        = (pl.left < cl.left ? pl.left : cl.left) - 5
            top         = (pl.top < cl.top ? pl.top : cl.top)
            width       = (pl.right > cl.right ? pl.right : cl.right) - left + 10
            height      = Math.abs(pl.top - cl.top) + 24 + 10

            let fromX   = Math.abs(pl.width) + 10
            let fromY   = 16
            let toX     = cl.left - left - 5
            let toY     = cl.top - top + 16
            let midX    = fromX + ((toX - fromX) / 2)
            let midY    = fromY + ((toY - fromY) / 2)

            p = "M" + fromX + " " + fromY + " Q " + (fromX + 20) + " " + fromY + ", " + midX + " " + midY + " T " + toX + " " + toY
        }
        return(
            <svg className={tagLineDependency} width={width} height={height} style={{position: 'fixed', left: left, top: top}} xmlns="http://www.w3.org/2000/svg">
                {this.state.draw &&
                    <path d={p} stroke="black" fill="transparent"/>
                }
            </svg>
        )
    }
}

class ProjectLine extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            start: this.props.start,
            blockAnimation: this.props.blockAnimation,
            offset: this.props.offset,
            scale: this.props.scale,
            line: this.props.line,
            activeLine: this.props.activeLine,
            lines: this.props.lines,
            onUpdate: this.props.onUpdate,
            onActiveLine: this.props.onActiveLine,
            onChangeTop: this.props.onChangeTop,
            viewTop: this.props.viewTop,
            viewBottom: this.props.viewBottom,
            disabled: false,
            collapse: this.props.collapse,
            onScreen: false,
            action: TAG_ACTION_BAR_NONE,
        }
    }
    componentWillMount()
    {
        this.onScreen()
    }
    componentDidMount()
    {
        window.addEventListener('scroll', this.onScreen.bind(this));
        this.onScreen()
    }
    componentWillUnmount()
    {
        window.removeEventListener('scroll', this.onScreen.bind(this));
    }
    componentWillUpdate(nextProps, nextState)
    {
        if (nextState.collapse != this.state.collapse) this.onScreen()
        if (nextState.line.instance != this.state.line.instance) this.props.onUpdate();
        if (nextState.line.top != this.state.line.top) this.props.onUpdate();

        this.onScreen()
    }
    initDimensions()
    {
        let left = this.left(this.props.scale)
        let width = this.width(this.props.scale)

        this.props.line.left = left
        this.props.line.width = width
        this.props.line.right = left + Math.abs(width)
        //this.onScreen()
    }
    /*
    componentDidMount()
    {
        this.startLoop();
    }
    componentWillUnmount()
    {
        this.stopLoop();
    }
    startLoop()
    {
        if(!this._frameId)
        {
            this._frameId = window.requestAnimationFrame( this.loop.bind(this) );
        }
    }
    loop()
    {
        if(this)
        {
            let delta = -this.now + (this.now = (new Date()).getTime());
            this.processActions(delta)
        }
        this.frameId = window.requestAnimationFrame( this.loop.bind(this) )
    }
    stopLoop()
    {
        window.cancelAnimationFrame( this._frameId );
    }
    */
    onDragStart(e)
    {
        let action = this.state.action
        switch (e.target.className)
        {
            case tagLineContentBar:
            case tagLineContentBarProgress:         action = TAG_ACTION_BAR_MOVE;        break;
            case tagLineContentBarProgressSlider:   action = TAG_ACTION_BAR_PROGRESS;    break;
            case tagLineContentBarStart:            action = TAG_ACTION_BAR_START;       break;
            case tagLineContentBarEnd:              action = TAG_ACTION_BAR_END;         break;
            case tagLineContent:                    action = TAG_ACTION_CANVAS_MOVE;     break;
            default:                                action = TAG_ACTION_BAR_NONE;        break;
        }

        if(action != TAG_ACTION_BAR_NONE)
        {
            const startX = typeof e.clientX === 'undefined' ? e.changedTouches[0].clientX : e.clientX;
            const startY = typeof e.clientY === 'undefined' ? e.changedTouches[0].clientY : e.clientY;
            const initX = startX
            const initY = startY

            console.log("initX", initX);

            const state = {
                dragging: true,
                action,
                startX,
                startY,
                initX,
                initY,
            };

            if(action != TAG_ACTION_CANVAS_MOVE) e.stopPropagation();

            this.props.onActiveLine(this.props.line.id)
            this.setState(state);
        }
    }
    onDragMove(e)
    {
        if (!this.state.dragging) return

        const x = typeof e.clientX === 'undefined' ? e.changedTouches[0].clientX : e.clientX;
        const y = typeof e.clientY === 'undefined' ? e.changedTouches[0].clientY : e.clientY;
        const dx = x - this.state.startX;
        const dy = y - this.state.startY;

        switch (this.state.action)
        {
            case TAG_ACTION_BAR_PROGRESS:
                let left = this.left(this.props.scale)
                let width = Math.abs(this.width(this.props.scale))
                let progress = Math.clamp(x - left, 0, width)
                this.props.line.complete = Math.round((progress / width) * 100);
                break;
            case TAG_ACTION_BAR_MOVE:
                switch (this.props.scale) {
                    default:
                    case TAG_SCALE_DAY:
                    case TAG_SCALE_WEEK:
                        this.props.line.start   = this.props.line.start.plus({ milliseconds: ((dx / TAG_INTERVAL_WIDTH) * ProjectUtility.getSeconds(this.props.line.start, this.props.scale)).toFixed(8) });
                        this.props.line.end     = this.props.line.end.plus({ milliseconds: ((dx / TAG_INTERVAL_WIDTH) * ProjectUtility.getSeconds(this.props.line.end, this.props.scale)).toFixed(8) });
                        break;
                }
                break;
            case TAG_ACTION_BAR_END:
                switch (this.props.scale) {
                    default:
                    case TAG_SCALE_DAY:
                    case TAG_SCALE_WEEK:    this.props.line.end     = this.props.line.end.plus({ milliseconds: ((dx / TAG_INTERVAL_WIDTH) * ProjectUtility.getSeconds(this.props.line.end, this.props.scale)).toFixed(8) });   break;
                }

                break;
            default: break;
        }
        this.props.line.left = this.left(this.props.scale)
        this.props.line.width = this.width(this.props.scale)

        this.props.onUpdate();

        this.setState({
            startX: x,
            startY: y,
        })
    }
    onDragEnd(e)
    {
        const mouseDistance = ProjectUtility.getDistance(
            this.state.initX,
            this.state.initY,
            this.state.startX,
            this.state.startY
        )
        console.log(mouseDistance);
        this.setState({ dragging: false,
                        startX: 0,
                        startY: 0,
                        initX: 0,
                        initY: 0,
                    });

        //const top = this.top()
        //this.props.line.top = top

        if(mouseDistance < TAG_START_DRAG_DISTANCE_MIN)
        {
            if(this.props.line.id == this.props.activeLine)
            {
                console.log("toggle collapse: ", this.props.line.id, this.props.activeLine, this.props.line.collapse);
                /*this.setState({
                    collapse: !this.state.collapse
                })*/
                this.props.line.collapse = !this.props.line.collapse
            }

            if(!this.state.collapse)
            {
                //this.props.line.fragments.map((l, index) =>
                //    l.instance.onScreen()
                //)
            }
        }
    }
    left(scale)
    {
        let left = 0
        if(!this.props.start) return left
        switch (scale) {
            default:
            case TAG_SCALE_DAY:     left = (this.props.line.start.diff(this.props.start, 'days').days * TAG_INTERVAL_WIDTH) + this.props.offset;    break;
            case TAG_SCALE_WEEK:    left = (this.props.line.start.diff(this.props.start, 'weeks').weeks * TAG_INTERVAL_WIDTH) + this.props.offset;  break;
        }
        return left
    }
    width(scale)
    {
        let width = 0
        if(!this.props.start) return width
        switch (scale) {
            default:
            case TAG_SCALE_DAY:     width = (this.props.line.start.diff(this.props.line.end, 'days').days * TAG_INTERVAL_WIDTH);    break;
            case TAG_SCALE_WEEK:    width = (this.props.line.start.diff(this.props.line.end, 'weeks').weeks * TAG_INTERVAL_WIDTH);  break;
        }
        return width
    }
    processActions(delta)
    {
        for (var i = 0; i < this.state.line.actions.length; i++)
        {
            let action = this.state.line.actions[i]
            action.timer += delta

            switch (action.type)
            {
                case TAG_ACTION_SCALE:
                    if(action.timer > 0 && action.timer < TAG_SCALE_DURATION)
                    {
                        let from    = this.left(action.from)
                        let to      = this.left(action.to)
                        let left    = ProjectUtility.easeInOutBack(action.timer, from, to, TAG_SCALE_DURATION)
                        this.leftVal = left
                        this.setState({ left });
                    }
                    break;
                default: break;
            }
        }
        this.state.line.actions = this.state.line.actions.filter(function(action) {
            return action.timer < TAG_SCALE_DURATION
        })
    }
    onScreen()
    {
        let isOnScreen = true
        const l = this.props.line
        const bottom = l.top + TAG_LINE_HEADER_HEIGHT

        if(
            (l.top > (this.props.viewTop) && l.top < (this.props.viewBottom + 200)) ||
            (bottom < this.props.viewBottom && bottom > (this.props.viewTop - 200)) ||
            (l.top < this.props.viewTop && bottom > this.props.viewBottom)
        ) isOnScreen = true
        else if(this.props.viewTop == 0 || this.props.viewBottom == 0) isOnScreen = true
        else isOnScreen = false

        if(this.state.onScreen != isOnScreen) this.setState({ onScreen: isOnScreen })
    }
    render()
    {
        let left = this.props.line.left
        let width = this.props.line.width
        /*
        if(this.state.onScreen)
        {
            left = this.left(this.props.scale)
            width = this.width(this.props.scale)
        }
        this.props.line.left = left
        this.props.line.width = width
        */
        this.props.line.right = left + Math.abs(width)
        let completeWidth = (width * (this.props.line.complete / 100))
        let animate = 'none'
        if(!this.props.blockAnimation && !this.state.dragging) animate = 'all 500ms ease-in-out'
        const parentLine = this.props.line
        const animateSlider = 'all 100ms ease-in-out'
        const topPx = this.props.line.top + 'px';
        const leftPx = left + 'px';
        const widthPx = Math.abs(width) + 'px';
        const completeWidthPx = (Math.abs(completeWidth) ) + 'px';
        const startLeftPx = (left - 20) + 'px';
        const endLeftPx = (left + Math.abs(width) - 13) + 'px';
        const sliderLeftPx = Math.clamp(left + Math.abs(completeWidth),
                                (left),
                                (left + 5) > (left + (Math.abs(completeWidth) - 10)) ? (left + 5) : (left + (Math.abs(completeWidth) - 10))
                            ) + 'px';
        const { disabled } = this.state;
        return (
            <div>
                <div className={tagLine}
                    ref={(el) => this.instance = el }
                    onMouseDown={this.onDragStart.bind(this)}
                    onTouchStart={this.onDragStart.bind(this)}
                    onMouseMove={this.onDragMove.bind(this)}
                    onTouchMove={this.onDragMove.bind(this)}
                    onMouseUp={this.onDragEnd.bind(this)}
                    onTouchEnd={this.onDragEnd.bind(this)}

                    style={{top: topPx}}
                >
                    <div className={tagLineInfo}>
                        {this.props.line.id}
                    </div>
                    <div className={tagLineContent}>
                        <div className={tagLineContentContainer}>
                            <div className={tagLineContentHeader}>
                                <div className={tagLineContentBar} style={{width: widthPx, left: leftPx, transition: animate}}></div>
                                <div className={tagLineContentBarProgress} style={{width: completeWidthPx, left: leftPx, transition: animate}} ></div>
                                <div className={tagLineContentBarEnd} style={{left: endLeftPx, transition: animate}} ></div>
                                <div className={tagLineContentBarProgressSlider} style={{left: sliderLeftPx, transition: animate}} ></div>
                                &nbsp;
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.onScreen && !this.props.collapse &&
                    this.state.line.fragments.map((l, index) =>
                        <ProjectLine
                            ref={(el) => l.instance = el }
                            line={l}
                            activeLine={this.props.activeLine}
                            lines={this.props.lines}
                            key={l.id}
                            collapse={l.collapse}
                            onUpdate={this.props.onUpdate}
                            onActiveLine={this.props.onActiveLine}
                            onChangeTop={this.props.onChangeTop}
                            offset={this.props.offset}
                            blockAnimation={this.props.blockAnimation}
                            start={this.props.start}
                            scale={this.props.scale}
                            viewTop={this.props.viewTop}
                            viewBottom={this.props.viewBottom}
                        />
                    )
                }
                {this.props.children}
            </div>
        )
    }
}

class Project extends React.Component
{
    constructor(props)
    {
        super(props)

        let startDate = new DateTime.local(2018, 7, 2)
        this.state = {
            matrix: [1, 0, 0, 1, 0, 0],
            dragging: false,
            resizing: false,
            dragOffset: 0,
            viewOffset: 0,
            viewTop: 0,
            viewBottom: 0,
            timeline: {
                start: startDate,
                view: startDate,
                scale: TAG_SCALE_WEEK,
                activeLine: undefined,
                lines: [
                    {
                        id: "a",
                        label: "Top thing",
                        start: new DateTime.local(2018, 7, 1),
                        end: new DateTime.local(2018, 7, 6),
                        complete: 43,
                        collapse: false,
                        actions: [],
                        fragments: [{
                            id: "b",
                            label: "Taskorama",
                            start: new DateTime.local(2018, 7, 1),
                            end: new DateTime.local(2018, 7, 3),
                            complete: 100,
                            collapse: false,
                            actions: [],
                            fragments: []
                        },
                        {
                            id: "c",
                            label: "Yo boy",
                            start: new DateTime.local(2018, 7, 3),
                            end:new DateTime.local(2018, 7, 5),
                            complete: 25,
                            collapse: false,
                            actions: [],
                            fragments: []
                        },
                        {
                            id: "d",
                            label: "Another",
                            start: new DateTime.local(2018, 7, 5),
                            end:new DateTime.local(2018, 7, 6),
                            complete: 0,
                            collapse: false,
                            actions: [],
                            fragments: []
                        }]
                    },
                    {
                        id: "a2",
                        label: "Second top",
                        start: new DateTime.local(2018, 7, 8),
                        end: new DateTime.local(2018, 7, 25),
                        complete: 13,
                        collapse: false,
                        actions: [],
                        fragments: [{
                            id: "b2",
                            label: "Task the mask",
                            start: new DateTime.local(2018, 7, 8),
                            end: new DateTime.local(2018, 7, 14),
                            complete: 10,
                            collapse: false,
                            actions: [],
                            fragments: []
                        },
                        {
                            id: "c2",
                            label: "Yo task thing",
                            start: new DateTime.local(2018, 7, 12),
                            end:new DateTime.local(2018, 7, 15),
                            complete: 25,
                            collapse: false,
                            actions: [],
                            fragments: []
                        },
                        {
                            id: "d2",
                            label: "Hey thing ",
                            start: new DateTime.local(2018, 7, 15),
                            end:new DateTime.local(2018, 7, 25),
                            complete: 0,
                            collapse: false,
                            actions: [],
                            fragments: [{
                                id: "b3",
                                label: "Taskorama",
                                start: new DateTime.local(2018, 7, 11),
                                end: new DateTime.local(2018, 7, 13),
                                complete: 100,
                                collapse: false,
                                actions: [],
                                fragments: [{
                                    id: "Here is a dev task",
                                    label: "Taskorama",
                                    start: new DateTime.local(2018, 7, 18),
                                    end: new DateTime.local(2018, 7, 23),
                                    complete: 100,
                                    collapse: false,
                                    actions: [],
                                    fragments: []
                                },
                                {
                                    id: "d4",
                                    label: "Another",
                                    start: new DateTime.local(2018, 7, 20),
                                    end:new DateTime.local(2018, 7, 26),
                                    complete: 0,
                                    collapse: false,
                                    actions: [],
                                    fragments: []
                                }]
                            },
                            {
                                id: "c3",
                                label: "Yo boy",
                                start: new DateTime.local(2018, 7, 13),
                                end:new DateTime.local(2018, 7, 15),
                                complete: 25,
                                collapse: false,
                                actions: [],
                                fragments: []
                            },
                            {
                                id: "d3",
                                label: "Another",
                                start: new DateTime.local(2018, 7, 15),
                                end:new DateTime.local(2018, 7, 16),
                                complete: 0,
                                collapse: false,
                                actions: [],
                                fragments: []
                            }]
                        }]
                    },
                ],
                dependencies: [{
                    parent: "a",
                    child: "a2",
                },{
                    parent: "a",
                    child: "c2",
                },{
                    parent: "a2",
                    child: "d3",
                },{
                    parent: "a",
                    child: "d4",
                }],
                intervalData: []
            }
        }
    }
    getLine(fragments, id)
    {
        let line = undefined
        for(let i = 0; i < fragments.length; i++)
        {
            if(fragments[i].id == id) line = fragments[i]
            if(fragments[i].fragments.length > 0)
            {
                let children = this.getLine(fragments[i].fragments, id)
                if(children != undefined) line = children
            }
        }
        return line
    }
    getParentLine(fragments, id, prospect)
    {
        let parent = undefined
        for(let i = 0; i < fragments.length; i++)
        {
            if(fragments[i].id == id)
            {
                if(prospect != undefined)
                    parent = prospect
                return parent
            }
            if(fragments[i].fragments.length > 0)
            {
                let tempParent = fragments[i]
                let parentTest = this.getParentLine(fragments[i].fragments, id, tempParent)
                if(parentTest != undefined)
                {
                    parent = parentTest
                    return parent
                }
            }
        }
        return parent
    }
    changeStart(e)
    {
        e.stopPropagation();
    }
    resizing()
    {
        this.setState({resizing: true});
        this.offScreen()
        setTimeout(
            function()
            {
                this.setState({resizing: false});
            }
            .bind(this),
            100
        );
    }
    componentDidMount()
    {
        window.addEventListener("resize", this.resizing.bind(this));
        window.addEventListener('scroll', this.offScreen.bind(this));
        //this.measureLineHeights(this.state.timeline.lines, 0)
    }
    componentWillUnmount()
    {
        window.removeEventListener("resize", this.resizing.bind(this));
        window.removeEventListener('scroll', this.offScreen.bind(this));
    }
    getData()
    {
        console.log("PROJECT GET DATA");
    }
    getHeaderHeight()
    {
        return TAG_HEADER_HEIGHT + TAG_INTERVAL_HEADER_HEIGHT
    }
    left(scale, line)
    {
        let left = 0
        if(!this.state.timeline.start) return left
        switch (scale) {
            default:
            case TAG_SCALE_DAY:     left = (line.start.diff(this.state.timeline.start, 'days').days * TAG_INTERVAL_WIDTH) + (this.state.dragOffset + this.state.viewOffset);    break;
            case TAG_SCALE_WEEK:    left = (line.start.diff(this.state.timeline.start, 'weeks').weeks * TAG_INTERVAL_WIDTH) + (this.state.dragOffset + this.state.viewOffset);  break;
        }
        return left
    }
    width(scale, line)
    {
        let width = 0
        if(!this.state.timeline.start) return width
        switch (scale) {
            default:
            case TAG_SCALE_DAY:     width = (line.start.diff(line.end, 'days').days * TAG_INTERVAL_WIDTH);    break;
            case TAG_SCALE_WEEK:    width = (line.start.diff(line.end, 'weeks').weeks * TAG_INTERVAL_WIDTH);  break;
        }
        return width
    }
    measureLineHeights(fragments, top)
    {
        fragments.map((l, index) =>
        {
            l.visible = true
            const parentLine = this.getParentLine(this.state.timeline.lines, l.id)
            if(parentLine != undefined)
            {
                if(!parentLine.collapse)
                    top += TAG_LINE_HEADER_HEIGHT
                else
                    l.visible = true
            }
            else
                top += TAG_LINE_HEADER_HEIGHT

            l.top = top
            l.left = this.left(this.state.timeline.scale, l)
            l.width = this.width(this.state.timeline.scale, l)
            //l.right = l.left + l.width
            top = this.measureLineHeights(l.fragments, top)
        })
        return top
    }
    renderDependency(key, pl, cl)
    {
        let left, top, width, height
        let p = ""

        if( pl.left     == undefined ||
            pl.right    == undefined ||
            pl.width    == undefined ||
            pl.top      == undefined ||
            cl.left     == undefined ||
            cl.right    == undefined ||
            cl.width    == undefined ||
            cl.top      == undefined
        ) return

        left        = (pl.left < cl.left ? pl.left : cl.left) - 5
        top         = (pl.top < cl.top ? pl.top : cl.top)
        width       = Math.abs((pl.right > cl.right ? pl.right : cl.right) - left) + 10
        height      = Math.abs(pl.top - cl.top) + 10 + 55

        let fromX   = Math.abs(pl.width) + 10
        let fromY   = 15
        let toX     = cl.left - left - 5
        let toY     = cl.top - top + 15
        let midX    = fromX + ((toX - fromX) / 2)
        let midY    = fromY + ((toY - fromY) / 2)

        p = "M" + fromX + " " + fromY + " Q " + (fromX + 20) + " " + fromY + ", " + midX + " " + midY + " T " + toX + " " + toY

        return(
            <svg key={pl.id + cl.id} className={tagLineDependency} width={width} height={height} style={{left: left, top: top}} xmlns="http://www.w3.org/2000/svg">
                <path d={p} stroke="rgba(255,0,0,0.5)" strokeDasharray="1,2" strokeWidth="1" strokeLinecap="round" fill="transparent"/>
            </svg>
        )
    }
    drawDependencies()
    {
        return (
            this.state.timeline.dependencies.map((d, index) =>
                this.renderDependency(
                    d.parent + d.child,
                    this.getLine(this.state.timeline.lines, d.parent),
                    this.getLine(this.state.timeline.lines, d.child)
                )
            )
        )
    }
    renderIntervalBackground(date, left, oddeven)
    {
        const leftPx = left + 'px'
        const widthPx = TAG_INTERVAL_WIDTH + 'px'
        const className = tagInterval + ' ' + oddeven
        return (
            <div key={date} className={className} style={{left: leftPx, width: widthPx}}></div>
        )
    }
    renderIntervalLabel(date, left)
    {
        const leftPx = left + 'px'
        const widthPx = TAG_INTERVAL_WIDTH + 'px'
        const className = tagIntervalInfo
        return (
            <div key={date} className={className} style={{left: leftPx, width: widthPx}}>
                <div className={tagIntervalLabel}>{date.toLocaleString(DateTime.DATE_MED)}</div>
            </div>
        )
    }
    prepareInterval()
    {
        const t = this.state.timeline
        const deltaOffset = this.state.dragOffset - (Math.floor(this.state.dragOffset / TAG_INTERVAL_WIDTH) * TAG_INTERVAL_WIDTH)
        switch (t.scale) {
            default:
            case TAG_SCALE_DAY:
                t.view = t.start.minus({days: Math.floor(this.state.dragOffset / TAG_INTERVAL_WIDTH)})
                break;
            case TAG_SCALE_WEEK:
                t.view = t.start.minus({weeks: Math.floor(this.state.dragOffset / TAG_INTERVAL_WIDTH)})
                break;
        }
        for(let i = -TAG_INTERVAL_BUFFER; i < TAG_INTERVAL_BUFFER; i++)
        {
            let intervalDate = 0
            let left = 0
            let oddeven = odd
            switch (t.scale) {
                default:
                case TAG_SCALE_DAY:
                    intervalDate = this.state.timeline.view.startOf('day').plus({days: i})
                    left = (intervalDate.diff(this.state.timeline.view, 'days').days * TAG_INTERVAL_WIDTH) + deltaOffset + this.state.viewOffset;
                    oddeven = intervalDate.ordinal % 2 == 0 ? even : odd
                    break;
                case TAG_SCALE_WEEK:
                    intervalDate = this.state.timeline.view.startOf('week').plus({weeks: i})
                    left = (intervalDate.diff(this.state.timeline.view, 'weeks').weeks * TAG_INTERVAL_WIDTH) + deltaOffset + this.state.viewOffset;
                    oddeven = intervalDate.weekNumber % 2 == 0 ? even : odd
                    break;
            }
            t.intervalData[i + TAG_INTERVAL_BUFFER] = {
                date: intervalDate,
                left,
                oddeven
            }
        }
    }
    drawIntervalLabel(t)
    {
        return (
            t.intervalData.map((_i) =>
                this.renderIntervalLabel(_i.date, _i.left)
            )
        )
    }
    drawIntervalBackground(t)
    {
        return (
            t.intervalData.map((_i) =>
                this.renderIntervalBackground(_i.date, _i.left, _i.oddeven)
            )
        )
    }
    onDragStart(e)
    {
        const startX = typeof e.clientX === 'undefined' ? e.changedTouches[0].clientX : e.clientX;
        const startY = typeof e.clientY === 'undefined' ? e.changedTouches[0].clientY : e.clientY;
        const state = {
            dragging: true,
            startX,
            startY,
        };

        e.stopPropagation();
        this.setState(state);
    }
    onDragMove(e)
    {
        if (!this.state.dragging) return

        const x = typeof e.clientX === 'undefined' ? e.changedTouches[0].clientX : e.clientX;
        const y = typeof e.clientY === 'undefined' ? e.changedTouches[0].clientY : e.clientY;
        const dx = x - this.state.startX;
        const dy = y - this.state.startY;
        this.state.dragOffset += dx;

        this.setState({
            startX: x,
            startY: y,
        });
    }
    onDragEnd()
    {
        this.setState({ dragging: false });
    }
    onScroll(e)
    {
        console.log(e);

    }
    zoomIn()
    {
        let newScale = 0
        switch (this.state.timeline.scale)
        {
            case TAG_SCALE_YEAR:    newScale = TAG_SCALE_MONTH;     break;
            case TAG_SCALE_MONTH:   newScale = TAG_SCALE_WEEK;      break;
            case TAG_SCALE_WEEK:    newScale = TAG_SCALE_DAY;       break;
            case TAG_SCALE_DAY:     newScale = TAG_SCALE_HOUR;      break;
            case TAG_SCALE_HOUR:    newScale = TAG_SCALE_MINUTE;    break;
            case TAG_SCALE_MINUTE:  newScale = TAG_SCALE_SECOND;    break;
            default:                newScale = TAG_SCALE_SECOND;    break;
        }
        this.adjustView(this.state.timeline.scale, newScale)
        this.setState({ timeline: { ...this.state.timeline, scale: newScale} });
        this.forceUpdate()
    }
    zoomOut()
    {
        let newScale = 0
        switch (this.state.timeline.scale)
        {
            default:                newScale = TAG_SCALE_YEAR;     break;
            case TAG_SCALE_MONTH:   newScale = TAG_SCALE_YEAR;     break;
            case TAG_SCALE_WEEK:    newScale = TAG_SCALE_MONTH;    break;
            case TAG_SCALE_DAY:     newScale = TAG_SCALE_WEEK;     break;
            case TAG_SCALE_HOUR:    newScale = TAG_SCALE_DAY;      break;
            case TAG_SCALE_MINUTE:  newScale = TAG_SCALE_HOUR;     break;
            case TAG_SCALE_SECOND:  newScale = TAG_SCALE_MINUTE;   break;
        }
        this.adjustView(this.state.timeline.scale, newScale)
        this.setState({ timeline: { ...this.state.timeline, scale: newScale} });
        this.forceUpdate()
    }
    zoomTo(newScale)
    {
        console.log(newScale.target.value);

        this.adjustView(this.state.timeline.scale, newScale.target.value)
        this.setState({ timeline: { ...this.state.timeline, scale: newScale.target.value} });
        this.forceUpdate()
    }
    setViewOffset()
    {
        const viewOffset = this.props.size.width / 2
        this.state.viewOffset = viewOffset
    }
    pushScaleAction(line, from, to, timer)
    {
        let action = (timer) => ({
            type:   TAG_ACTION_SCALE,
            from:   TAG_SCALE_WEEK,
            to:     TAG_SCALE_DAY,
            timer
        })

        line.actions.push(action(timer))
        for(let i = 0; i < line.fragments.length; i++)
        {
            this.pushScaleAction(line.fragments[i], from, to, timer)
        }
    }
    adjustView(initialScale, newScale)
    {
        const fromSeconds   = ProjectUtility.getSeconds(this.state.timeline.start, initialScale)
        const toSeconds     = ProjectUtility.getSeconds(this.state.timeline.start, newScale)
        const direction     = Math.sign(fromSeconds-toSeconds)
        this.state.dragOffset *= (fromSeconds/toSeconds)

        for(let i = 0; i < this.state.timeline.lines.length; i++)
        {
            this.pushScaleAction(this.state.timeline.lines[i], initialScale, newScale, -100)
        }
    }
    zoomLabel()
    {
        switch (this.state.timeline.scale)
        {
            case TAG_SCALE_YEAR:    return "YEAR";      break;
            case TAG_SCALE_MONTH:   return "MONTH";     break;
            case TAG_SCALE_WEEK:    return "WEEK";      break;
            case TAG_SCALE_DAY:     return "DAY";       break;
            case TAG_SCALE_HOUR:    return "HOUR";      break;
            case TAG_SCALE_MINUTE:  return "MINUTE";    break;
            case TAG_SCALE_SECOND:  return "SECOND";    break;
            default:                return "SECOND";    break;
        }
    }
    offScreen()
    {
        const rectConatiner = this.tagContainer.getBoundingClientRect();
        this.setState({
            viewTop: rectConatiner.top,
            viewBottom: rectConatiner.height,
        });
    }
    onLineUpdate()
    {
        console.log("onLineUpdate");
        /**
        //  NOTE(JP):   Potential dispatch point for data change
        //
        //  This is how we can update UI on Line drag (and redraw
        //  connected elementes, i.e. dependencies) however we may
        //  wish to hold server updates until changes are 'saved'
        //  or when a user action has finished, i.e. onDragEnd
        //
        //  For now, let's use forceUpdate to redraw dependencies
        **/
        this.forceUpdate()
    }
    onActiveLine(id)
    {
        const rectConatiner = this.tagContainer.getBoundingClientRect();
        this.setState({
            timeline: {...this.state.timeline, activeLine: id}
        });
    }
    onChangeTop(id, newTop)
    {
        const line = this.getLine(this.state.timeline.lines, id)
        line.top = newTop
        console.log("newTop", line.top);
        console.log("line.instance", line.instance);

        if(line.instance != undefined)
        {
            const top = ReactDOM.findDOMNode(line.instance).getBoundingClientRect().top
            console.log("line top", top);

        }
        /*
        this.setState({
            timeline: {...this.state.timeline, activeLine: id}
        });
        */
    }
    render()
    {
        const canvasHeightPx        = this.measureLineHeights(this.state.timeline.lines, 0)
        const intervals             = this.prepareInterval()
        const intervalLabels        = this.drawIntervalLabel(this.state.timeline)
        const intervalBackground    = this.drawIntervalBackground(this.state.timeline)
        const dependencies          = this.drawDependencies()
        const zoomLabel             = this.zoomLabel()
        const blockAnimation        = this.state.dragging || this.state.resizing
        this.setViewOffset()
        return (
            <div>
                <Layout className={tagToolbarContainer} style={{backgroundColor: '#3e4243', position: 'fixed', display: 'block', top: '70px', zIndex: 10, width: '100%', height: '40px'}}>
                    <Content style={{textAlign: 'center', marginTop: '4px'}}>
                        <Radio.Group defaultValue={this.state.timeline.scale} size="small" buttonStyle="solid" onChange={this.zoomTo.bind(this)}>
                          <Radio.Button value="TAG_SCALE_YEAR">Year</Radio.Button>
                          <Radio.Button value="TAG_SCALE_MONTH">Month</Radio.Button>
                          <Radio.Button value="TAG_SCALE_WEEK">Week</Radio.Button>
                          <Radio.Button value="TAG_SCALE_DAY">Day</Radio.Button>
                          <Radio.Button value="TAG_SCALE_HOUR">Hour</Radio.Button>
                          <Radio.Button value="TAG_SCALE_MINUTE">Minute</Radio.Button>
                        </Radio.Group>
                    </Content>
                </Layout>
                <div className={container}
                    ref={(el) => this.tagContainer = el }
                    onMouseDown={this.onDragStart.bind(this)}
                    onTouchStart={this.onDragStart.bind(this)}
                    onMouseMove={this.onDragMove.bind(this)}
                    onTouchMove={this.onDragMove.bind(this)}
                    onMouseUp={this.onDragEnd.bind(this)}
                    onTouchEnd={this.onDragEnd.bind(this)}
                >
                    <div className={tagIntervalContainer}>
                        {intervalBackground}
                    </div>
                    <div className={tagCanvas}
                        ref={(el) => this.tagCanvas = el }
                        style={{height: canvasHeightPx}}
                    >
                        {this.state.timeline.lines.map((l, index) =>
                            <ProjectLine
                                line={l}
                                activeLine={this.state.timeline.activeLine}
                                lines={this.state.timeline.lines}
                                key={l.id}
                                collapse={l.collapse}
                                offset={this.state.dragOffset + this.state.viewOffset}
                                blockAnimation={blockAnimation}
                                start={this.state.timeline.start}
                                scale={this.state.timeline.scale}
                                viewTop={this.state.viewTop}
                                viewBottom={this.state.viewBottom}
                                onUpdate={this.onLineUpdate.bind(this)}
                                onActiveLine={this.onActiveLine.bind(this)}
                                onChangeTop={this.onChangeTop.bind(this)}
                            />
                        )}
                        {dependencies}
                        <div className={tagIntervalLabelContainer}>
                            {intervalLabels}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const config    = { monitorHeight: true, monitorPosition: true };
const sizeMeHOC = sizeMe(config);
export default connect((state) => ({
    info: state.authenticatedUser.info,
}))(sizeMeHOC(Project))
