export const SET_CURRENT_CLAIM = 'SET_CURRENT_CLAIM'
export const SET_CLAIM_DETAIL = 'SET_CLAIM_DETAIL'
export const UNSET_CLAIM_DETAIL = 'UNSET_CLAIM_DETAIL'

import { setSession } from './session'

export function setCurrentClaim (claimId) {
    return {
        type: SET_CURRENT_CLAIM,
        claimId,
    }
}

export function setClaimDetail (claimId, detail) {
    return {
        type: SET_CLAIM_DETAIL,
        claimId,
        detail,
    }
}

export function handleGetClaimDetail (token, login, claim) {
    return (dispatch) => {
        return fetch('/api/get-claim', {
                method: 'POST',
                body: JSON.stringify({token, login, claim}),
                headers: { 'Content-Type': 'application/json' }
            })
            .then((res) => res.json())
            .then(({token, result, details, logs, roles, reminders, photos, documents }) => {
                if(result == 'Success')
                {
                    dispatch(setSession(token))
                    dispatch(setClaimDetail(
                        claim,
                        {
                            token,
                            result,
                            details,
                            logs,
                            roles,
                            reminders,
                            photos,
                            documents
                        }
                    ))
                }
                else return result
            })
    }
}

export function unsetClaimDetail () {
    return {
        type: UNSET_CLAIM_DETAIL,
    }
}
