import {
    SET_TIMELINE,
    UNSET_TIMELINE,
} from '../actions/timeline'

export default function timeline (state = null, action) {
    switch (action.type) {
        case SET_TIMELINE :
            return action.timeline
        case UNSET_TIMELINE :
            return null
        default :
            return state
    }
}
