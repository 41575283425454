import React, { PropTypes } from 'react'
import { connect } from 'react-redux'
import { Link } from "react-router-dom";
import { container, box } from './styles.css'
import { Row, Col, Layout, Table, Divider } from 'antd';
import sizeMe from 'react-sizeme'
import { SpringGrid, measureItems, makeResponsive, layout } from 'react-stonecutter';
import { Project } from 'components'

const { Header, Footer, Sider, Content } = Layout;

const Grid = measureItems(SpringGrid, {
  maxWidth: 1920,
  minPadding: 10,
  columnWidth: 240,
  measureImages: true
});
const GridStyles = {
    listStyle: 'none',
    display: 'flex'
}

class Home extends React.Component {
  state = {}
  clamp = (num, min, max) => {
      return num <= min ? min : num >= max ? max : num;
  }
  render () {
        const { columnWidth, gutterWidth, size } = this.props;
        let columns;
        let minPadding = 5;
        let flowTop = size.position.top;
        columns = this.clamp(Math.floor((((size.width - minPadding) / (235))) / 1), 1, 6);
    return (
        <div>Hi</div>
    )
  }
}

const config = { monitorHeight: true, monitorPosition: true };
const sizeMeHOC = sizeMe(config);

export default connect((state) => ({
    claimId: state.claim,
    info: state.authenticatedUser.info,
}))(sizeMeHOC(Home))
