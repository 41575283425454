export const SET_TIMELINE = 'SET_TIMELINE'
export const UNSET_TIMELINE = 'UNSET_TIMELINE'

export function setTimeline (timeline) {
    return {
        type: SET_TIMELINE,
        timeline,
    }
}

export function unsetTimeline () {
    return {
        type: UNSET_TIMELINE,
    }
}
