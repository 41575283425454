export const SET_AUTHENTICATED_USER = 'SET_AUTHENTICATED_USER'
export const UNSET_AUTHENTICATED_USER = 'UNSET_AUTHENTICATED_USER'
export const SET_USER_INFO = 'SET_USER_INFO'
export const UNSET_USER_INFO = 'UNSET_USER_INFO'

import { setSession } from './session'

export function setAuthenticatedUser (user) {
    return {
        type: SET_AUTHENTICATED_USER,
        user,
    }
}

export function unsetAuthenticatedUser () {
    return {
        type: UNSET_AUTHENTICATED_USER,
    }
}

export function setUserInfo (info) {
    return {
        type: SET_USER_INFO,
        info,
    }
}

export function handleGetUserInfo (token, login) {
    return (dispatch) => {
        return fetch('/api/get-user-info', {
                method: 'POST',
                body: JSON.stringify({token, login}),
                headers: { 'Content-Type': 'application/json' }
            })
            .then((res) => res.json())
            .then(({token, result, recents, actions, actionCount}) => {
                if(result == 'Success')
                {
                    dispatch(setSession(token))
                    dispatch(setUserInfo({
                        token,
                        result,
                        recents,
                        actions,
                        actionCount,
                    }))
                }
                else return info
            })
    }
}

export function unsetUserInfo () {
    return {
        type: UNSET_USER_INFO,
    }
}
